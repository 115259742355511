import React, { useRef, useEffect } from 'react';
import AceEditor from 'react-ace';
import beautify from 'js-beautify';
import autoload from '../../helpers/autoload';

import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-xcode';

const AceEditorComponent = ({ initialContent, inputName }) => {
  const hiddenInputRef = useRef(null);

  // Beautify the initial content
  const formattedContent = beautify.html(initialContent || '', {
    indent_size: 2, // Set the indentation size
    wrap_line_length: 80,
    preserve_newlines: true,
  });

  // Set the hidden input value on mount
  useEffect(() => {
    if (hiddenInputRef.current) {
      hiddenInputRef.current.value = formattedContent;
    }
  }, [formattedContent]);

  const handleEditorChange = (newValue) => {
    if (hiddenInputRef.current) {
      hiddenInputRef.current.value = newValue;
    }
  };

  return (
    <div className="cms-ace-editor-container">
      <AceEditor
        mode="html"
        theme="xcode"
        name="editor"
        value={formattedContent}
        onChange={handleEditorChange}
        fontSize={14}
        showPrintMargin={true}
        showGutter={true}
        highlightActiveLine={true}
        onLoad={(editorInstance) => {
          editorInstance.container.style.resize = 'both';
          // Resize editor on mouseup after resize
          document.addEventListener('mouseup', () => editorInstance.resize());
        }}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: true,
          showLineNumbers: true,
          wrapEnabled: true,
          tabSize: 2,
        }}
        style={{
          width: '95%',
          height: '400px',
        }}
      />
      <input type="hidden" name={inputName} ref={hiddenInputRef} />
    </div>
  );
};

export default autoload(AceEditorComponent, 'ace-editor');
