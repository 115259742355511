
import React, {useEffect, useRef} from 'react';

const Banner = ({content, buttonText, onClick, altButtonText, altOnClick, icon, style={}, raw }) => {
  const bannerRef = useRef(null);
  useEffect(() => {
    if (bannerRef.current) {
    }
  }, [content, raw]);
  return (
    <div className='banner' style={style} ref={bannerRef}>
      {raw ? raw : <p>{content}</p>}
      <div className='next-button-row' style={{width: '100%', justifyContent: altOnClick ? 'space-between' : 'end'}}>
        {(altButtonText && <div className='banner-button back-button' onClick={altOnClick}>{altButtonText}</div>)}
        {(buttonText && <div className='banner-button next-button' onClick={onClick}>{buttonText}</div>)}
      </div>
    </div>
  );
};

export default Banner;
