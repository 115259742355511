import React from 'react';
import ScanOptionMenu from './ScanOptionMenu';
import {
  categoryUri,
} from '../../helpers/displayHelpers';

const ScanRow = ({id, rowIdentifier, scan, optionsOpenID, toggleOptions, showSpinner, dismissScan, issueTicket, hostScanning, guestScanning, toggleGuestScanning, redeemForEvent}) => {
  const iconClass = () => {
    if (['can_issue', 'can_over_issue', 'can_comp', 'can_over_comp', 'can_sell_or_comp', 'can_over_sell_or_comp', 'event_selection', 'ticket_error'].includes(scan.state)) return 'fa e-ticket-attention-color fa-no-ticket fa-3x';
    if (['redeemed', 'guest_redeemed'].includes(scan.state)) return 'fa e-ticket-success-color fa-ticket-checked fa-3x';
    if (['approval_pending'].includes(scan.state)) return 'fa e-ticket-pending-color fa-provisional-pending fa-3x';
    if (['approval_denied'].includes(scan.state)) return 'fa e-ticket-error-color fa-provisional-decline fa-3x';
    if (['partially_redeemed'].includes(scan.state)) return 'fa e-ticket-attention-color fa-ticket-horz-blank fa-3x';
    if (['pending_sale'].includes(scan.state)) return 'fa e-ticket-pending-color fa-ticket-in-cart fa-3x';
    if (['badge_error'].includes(scan.state)) return 'fa e-ticket-error-color fa-badge-error fa-3x';
    if (['event_error'].includes(scan.state)) return 'fa e-ticket-error-color fa-event-error fa-3x';
    if (['pending_response'].includes(scan.state)) return 'fa e-ticket-pending-color fa-refresh fa-3x';
    if (['past_event', 'future_event'].includes(scan.state)) return 'fa e-ticket-attention-color fa-ticket-alert fa-3x';
  }

  const ticketCount = () => {
    if (scan.additional_tickets > 0) return `+${scan.additional_tickets}`;
  };

  const displayName = () => {
    if (scan.ticketholder) return `${scan.ticketholder.first_name} ${scan.ticketholder.last_name}`;
    return scan.badge_code;
  }

  const isOptionsOpen = () => { return optionsOpenID == scan.id || (guestScanning.enabled && guestScanning.host.id == id); }

  const openOptions = () => {
    if (isOptionsOpen()) return;  
    if (!['redeemed', 'guest_redeemed', 'approval_pending', 'pending_sale', 'approval_denied', 'pending_response'].includes(scan.state)) {
      toggleOptions(scan.id);
    }
  }
  
  const purchasedName = () => {
    if (scan.host_ticketholder.first_name != scan.ticketholder.first_name || scan.host_ticketholder.last_name != scan.ticketholder.last_name) {
      return <b style={{color: 'red'}}>{scan.host_ticketholder.first_name} {scan.host_ticketholder.last_name}</b>
    } else {
      return `${scan.host_ticketholder.first_name} ${scan.host_ticketholder.last_name}`
    }
  }

  return (
    <tr className='scan-filter-row' onClick={openOptions} id={`scan-row-${rowIdentifier}`} >
      <td style={{width: '80%'}}>
        { isOptionsOpen() ? (
          <ScanOptionMenu
            id={id}
            rowIdentifier={rowIdentifier}
            scan={scan}
            toggleOptions={toggleOptions}
            showSpinner={showSpinner}
            dismissScan={dismissScan}
            issueTicket={issueTicket}
            guestScanning={guestScanning}
            toggleGuestScanning={toggleGuestScanning}
            redeemForEvent={redeemForEvent}
          />
          )
          :
          (
            <span className='ticketholder-name' id={`ticketholder-name-${rowIdentifier}`}>
              <div>{displayName()}</div>
              {scan.state === 'pending_sale' && <div className='message'>Ticket transaction awaiting approval by {purchasedName()}</div>}
              {scan.state !== 'pending_sale' && scan.message && <div className='message'>{scan.message}</div>}
              {hostScanning && 
                <div className='event-result-container'>
                  <div className="event-icon text-center">
                    {scan?.event?.event_type && <img
                      alt="icon"
                      border={0}
                      src={categoryUri({'role': '', 'event_type': scan.event.event_type})}
                    />}
                  </div>
                  {scan.event && <div className='message event-title'>{scan.event.title}</div>}
                  {scan.event && <div className='message event-code'> {scan.event.game_code}</div>}
                </div>
              }
            </span>
          )
        }
      </td>
      <td style={{width: '20%',textAlign: 'right', paddingRight:'1em'}}>
        <span className='ticket-count-container' style={{columnSpan: 2}}>
          <div className='ticket-count-num'>
            {ticketCount()}
          </div>
          <i className={iconClass()} id={`scan-icon-${rowIdentifier}`}></i>
      </span>
      </td>
    </tr>
  );
};

export default ScanRow;