import React, { useState } from 'react';
import Modal from './Modal';
const ScanOptionMenu = ({ id, rowIdentifier, scan, toggleOptions, showSpinner, dismissScan, issueTicket, guestScanning, toggleGuestScanning, redeemForEvent }) => {
  const [confirmation, setConfirmation] = useState(false);
  const [eventSelection, setEventSelection] = useState(false);
  const [oversell, setOversell] = useState(false);
  const [comp, setComp] = useState(false);

  const displayName = () => {
    if (scan.ticketholder) return `${scan.ticketholder.first_name} ${scan.ticketholder.last_name}`;
    return scan.badge_code;
  }

  const openOverSellConfirmation = (oversell, comp) => {
    setConfirmation(true);
    setOversell(oversell);
    setComp(comp);
  };

  const selectChoice = (eventId) => {
    setEventSelection(false);
    toggleOptions(scan.id);
    redeemForEvent(scan.id, scan.badge_code, eventId)
  }

  const purchasedName = () => {
    if (scan.host_ticketholder.first_name != scan.ticketholder.first_name || scan.host_ticketholder.last_name != scan.ticketholder.last_name) {
      return <b style={{color: 'red'}}>{scan.host_ticketholder.first_name} {scan.host_ticketholder.last_name}</b>
    } else {
      return `${scan.host_ticketholder.first_name} ${scan.host_ticketholder.last_name}`
    }
  }


  const renderMessage = () => {
    switch (scan.state) {
      case 'badge_error':
      case 'event_error':
        return (
          <span className='ticketholder-name'>
            <div>{displayName()}</div>
            <div className='scan-options-menu'>
              <div className='message'>Do you want to clear the scan?</div>
            </div>
          </span>
        );
      case 'can_sell_or_comp':
        return (
          <span className='ticketholder-name'>
            <div>{displayName()}</div>
            <div className='scan-options-menu'>
              <div className='message'>You may sell or comp a ticket. To sell a ticket, the purchase must be approved by {purchasedName()}.</div>
            </div>
          </span>
        );
      case 'can_over_sell_or_comp':
        return (
          <span className='ticketholder-name'>
            <div>{displayName()}</div>
            <div className='scan-options-menu'>
              <div className='message'>You may sell or comp a ticket. To sell a ticket, the purchase must be approved by {purchasedName()}.</div>
              <div className='scan-warning-message'>This Event is SOLD OUT</div>
            </div>
          </span>
        );
      case 'can_comp':
        return (
          <span className='ticketholder-name'>
            <div>{displayName()}</div>
            <div className='scan-options-menu'>
              <div className='message'>The attendee doesn't have a gencon.com user account and therefore cannot approve the sale of a ticket. You can give them a complimentary ticket or ask them to go to an Event Registration location to purchase a ticket.</div>
            </div>
          </span>
        );
      case 'can_over_comp':
        return (
          <span className='ticketholder-name'>
            <div>{displayName()}</div>
            <div className='scan-options-menu'>
              <div className='message'>The attendee doesn't have a gencon.com user account and therefore cannot approve the sale of a ticket. You can give them a complimentary ticket or ask them to go to an Event Registration location to purchase a ticket.</div>
              <div className='scan-warning-message'>This Event is SOLD OUT</div>
            </div>
          </span>
        );
      case 'can_issue':
        return (
          <span className='ticketholder-name'>
            <div>{displayName()}</div>
            <div className='scan-options-menu'>
              <div className='message'>This is a free event, but please issue them a ticket so they are counted as an attendee.</div>
            </div>
          </span>
        );
      case 'can_over_issue':
        return (
          <span className='ticketholder-name'>
            <div>{displayName()}</div>
            <div className='scan-options-menu'>
              <div className='message'>This is a free event, but please issue them a ticket so they are counted as an attendee.</div>
              <div className='scan-warning-message'>This Event is SOLD OUT</div>
            </div>
          </span>
        );
      case 'partially_redeemed':
        return (
          <span className='ticketholder-name'>
            <div>{displayName()}</div>
            <div className='scan-options-menu'>
              <div className='guest-message'>
                You must scan the badge of {scan.ticketholder.first_name}'s guest to transfer a ticket to them and redeem it.
                {!guestScanning.enabled && <br />}
                {!guestScanning.enabled && <br />}
                {!guestScanning.enabled && 'Would you like to scan guest badges now?'}
              </div>
            </div>
          </span>
        );
      case 'event_selection':
        return (
          <span className='ticketholder-name'>
            <div>{displayName()}</div>
            <div className='scan-options-menu'>
              <div className='message'>{scan.ticketholder.first_name} has tickets for more than one for your current events. Select the event to redeem.</div>
            </div>
          </span>
        );
      case 'ticket_error':
        return (
          <span className='ticketholder-name'>
            <div>{displayName()}</div>
            <div className='scan-options-menu'>
              <div className='message'>{scan.ticketholder.first_name} has no tickets for your events. You can select an event in your schedule and sell or comp a ticket as needed.</div>
            </div>
          </span>
        );
      case 'future_event':
        return (
          <span className='ticketholder-name'>
            <div>{displayName()}</div>
            <div className='scan-options-menu'>
              <div className='message'>{scan.ticketholder.first_name} has a ticket for an event that cannot be scanned yet. Have {scan.ticketholder.first_name} come back within 30 minutes of the start of the event.</div>
            </div>
          </span>
        );
      case 'past_event':
        return (
          <span className='ticketholder-name'>
            <div>{displayName()}</div>
            <div className='scan-options-menu'>
              <div className='message'>{scan.ticketholder.first_name} has a ticket for an event that has already started. Have {scan.ticketholder.first_name} go to an Event Registration location to redeem the ticket.</div>
            </div>
          </span>
        );
    }
  }

  const renderButtons = () => {
    switch (scan.state) {
      case 'badge_error':
      case 'event_error':
      case 'ticket_error':
      case 'future_event':
      case 'past_event':
        return (
          <div className='scan-options-button'>
            <a className='button' onClick={() => toggleOptions(scan.id)}>Cancel</a>
            <a className='button' onClick={async () => { await dismissScan(scan.id); toggleOptions(scan.id); }}>Clear</a>
          </div>
        );
      case 'can_sell_or_comp':
        return (
          <div>
            <div className='scan-options-button'>
              <a className='button' onClick={async () => { await issueTicket(scan.id, false, false); toggleOptions(scan.id); }} id={`sell-ticket-button-${rowIdentifier}`}>Sell Ticket</a>
              <a className='button' onClick={async () => { await issueTicket(scan.id, false, true); toggleOptions(scan.id); }}>Comp Ticket</a>
            </div>
            <div className='scan-options-button'>
              <a className='button' onClick={() => toggleOptions(scan.id)}>Cancel</a>
              <a className='button' onClick={async () => { await dismissScan(scan.id); toggleOptions(scan.id); }}>Clear</a>
            </div>
          </div>
        );
      case 'can_over_sell_or_comp':
        return (
          <div>
            <div className='scan-options-button'>
              <a className='button' onClick={() => openOverSellConfirmation(true, false)}>Sell Ticket</a>
              <a className='button' onClick={() => openOverSellConfirmation(true, true)}>Comp Ticket</a>
            </div>
            <div className='scan-options-button'>
              <a className='button' onClick={() => toggleOptions(scan.id)}>Cancel</a>
              <a className='button' onClick={async () => { await dismissScan(scan.id); toggleOptions(scan.id); }}>Clear</a>
            </div>
          </div>
        );
      case 'can_comp':
        return (
          <div>
            <div className='scan-options-button'>
              <a className='button' onClick={async () => { await issueTicket(scan.id, false, true); toggleOptions(scan.id); }}>Comp Ticket</a>
            </div>
            <div className='scan-options-button'>
              <a className='button' onClick={() => toggleOptions(scan.id)}>Cancel</a>
              <a className='button' onClick={async () => { await dismissScan(scan.id); toggleOptions(scan.id); }}>Clear</a>
            </div>
          </div>
        );
      case 'can_over_comp':
        return (
          <div>
            <div className='scan-options-button'>
              <a className='button' onClick={() => openOverSellConfirmation(true, true)}>Comp Ticket</a>
            </div>
            <div className='scan-options-button'>
              <a className='button' onClick={() => toggleOptions(scan.id)}>Cancel</a>
              <a className='button' onClick={async () => { await dismissScan(scan.id); toggleOptions(scan.id); }}>Clear</a>
            </div>
          </div>
        );
      case 'can_issue':
        return (
          <div>
            <div className='scan-options-button'>
              <a className='button' onClick={async () => { await issueTicket(scan.id, false, false); toggleOptions(scan.id); }}>Issue Ticket</a>
            </div>
            <div className='scan-options-button'>
              <a className='button' onClick={() => toggleOptions(scan.id)}>Cancel</a>
              <a className='button' onClick={async () => { await dismissScan(scan.id); toggleOptions(scan.id); }}>Clear</a>
            </div>
          </div>
        );
      case 'can_over_issue':
        return (
          <div>
            <div className='scan-options-button'>
              <a className='button' onClick={() => openOverSellConfirmation(true, false)}>Issue Ticket</a>
            </div>
            <div className='scan-options-button'>
              <a className='button' onClick={() => toggleOptions(scan.id)}>Cancel</a>
              <a className='button' onClick={async () => { await dismissScan(scan.id); toggleOptions(scan.id); }}>Clear</a>
            </div>
          </div>
        );
      case 'partially_redeemed':
        return (
          <div>
            <div className='scan-options-button'>
              {(guestScanning.enabled && guestScanning.host.id == scan.id) ?
                <a className='button' onClick={() => toggleGuestScanning(scan.id)}>Stop Scanning Guest Badges</a>
                :
                <div>
                  <a className='button' onClick={() => toggleGuestScanning(scan.id)}>Scan Guest Badges</a>
                  <a className='button' onClick={() => toggleOptions(scan.id)}>Cancel</a>
                </div>
              }
            </div>
          </div>
        );
      case 'event_selection':
        return (
          <div>
            <div className='scan-options-button'>
              <a className='button' onClick={() => toggleOptions(scan.id)}>Cancel</a>
              <a className='button' onClick={() => setEventSelection(true)}>Select Event</a>
            </div>
          </div>
        );
    }
  }

  return (
    <span>
      {confirmation ?
        <span className='ticketholder-name'>
          <div>{displayName()}</div>
          <div className='scan-options-menu'>
            <div className='message'>This event is sold out. Are you sure it can accommodate another participant?</div>
          </div>
        </span>
        :
        renderMessage()}
      {showSpinner ?
        (
          <div className='scan-options-button'>
            <div className='css-spinner' />
          </div>
        ) :
        (
          confirmation  ?
            <div className='scan-options-button'>
              <a className='button' onClick={() => { setConfirmation(false); toggleOptions(scan.id); }}>Cancel</a>
              <a className='button' onClick={async () => { await issueTicket(scan.id, oversell, comp); setConfirmation(false); toggleOptions(scan.id); }}>Confirm</a>
            </div>
          :
          (
            eventSelection ?
            <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent:'center'}}>
              <Modal
                event_choices={scan.event_choices}
                selectChoice={selectChoice}
                cancel={() => { toggleOptions(scan.id); setEventSelection(false)}}
                />
            </div>
            :
            renderButtons()
          )
        )
      }
    </span>
  );
};

export default ScanOptionMenu;