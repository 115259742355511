import React, { Fragment, useState } from 'react';
import { useActionCable } from 'use-action-cable';

const ProgressTracker = ({ actionCableOn, channelName, completionMessage = "Process completed!" }) => {
  const [progressEntries, setProgressEntries] = useState([]);
  const [downloadUrl, setDownloadUrl] = useState(null);

  const channelHandlers = (channelInfo) => ({
    received: (msg) => {
      const newEntry = {
        progress: msg.progress,
        message: msg.message || `Progress: ${msg.progress}%`,
        completed: msg.completed || false,
      };

      setProgressEntries((currentEntries) => [newEntry, ...currentEntries]);

      // Set the download URL if the process is completed and a URL is provided
      if (msg.completed && msg.download_url) {
        setDownloadUrl(msg.download_url);
      }
    },
  });

  if (actionCableOn) {
    useActionCable(
      { channel: channelName, id: 1 },
      channelHandlers({ channel: channelName, id: 1 })
    );
  }

  // Get the latest entry for the progress bar
  const latestEntry = progressEntries[0];

  // Define styles for the progress bar and its container
  const progressBarContainerStyle = {
    padding: '10px',
    backgroundColor: '#f9f9f9',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px',
    marginBottom: '20px',
  };

  const progressBarStyle = {
    height: '36px',
    flex: '1',
  };

  const percentageLabelStyle = {
    minWidth: '50px',
    textAlign: 'right',
    fontWeight: 'bold',
    marginRight: '6px',
  };

  const emptyStateStyle = {
    padding: '20px',
    textAlign: 'center',
    backgroundColor: '#f2f2f2',
    borderRadius: '5px',
    fontStyle: 'italic',
    color: '#666',
    marginBottom: '20px',
  };

  return (
    <Fragment>
      {latestEntry ? (
        <div className='ribbon-row' style={progressBarContainerStyle}>
          <progress
            value={latestEntry.progress}
            max="100"
            style={progressBarStyle}
          ></progress>
          <span style={percentageLabelStyle}>
            {latestEntry.progress}%
          </span>
          {downloadUrl && (
            <a href={downloadUrl} download title="Download">
              <i className="fa fa-2x fa-download"></i>
            </a>
          )}
        </div>
      ) : (
        <div style={emptyStateStyle}>
          Waiting for progress updates...
        </div>
      )}
      <table className="records">
        <thead>
          <tr>
            <th>Message</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {progressEntries.length > 0 ? (
            progressEntries.map((entry, index) => (
              <tr key={index} className="graystriped">
                <td>{entry.message}</td>
                <td>
                  {entry.completed ? (
                    <b>Completed</b>
                  ) : (
                    'In Progress'
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2" style={{ textAlign: 'center', color: '#666', fontStyle: 'italic' }}>
                No progress records yet.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </Fragment>
  );
};

export default ProgressTracker;
