import React, {useState, useEffect} from 'react';
import axios from "axios";
import TasksChecklist from './TasksChecklist';
import Content from './Content';
import autoload from '../../helpers/autoload';

const TASK_TO_CONTENT_KEY = {
  'training-overview': 'web1.1',
  'preparing-for-training': 'web2.1',
  'select-interface': 'web3.1',
  'using-scan-interface': 'task3.1',
  'redeeming-tickets': 'task4.1',
  'redeeming-guest-tickets': 'task5.1',
  'dealing-with-problems': 'task7.1',
  'redeeming-more-guest-tickets': 'task8.1',
  'sell-comp-ticket': 'task6.1',
  'scan-all-events': 'task9.1',
  'sandbox': 'sandbox',
}

const ETicketingTraining = ({userID, currentTaskKey}) => {

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1000); // boolean
  const [trainingProgress, setTrainingProgress] = useState([]);
  const [contentKey, setContentKey] = useState(TASK_TO_CONTENT_KEY[currentTaskKey] || 'web1.1');



  const [scans, setScans] = useState([]); // list of ScanToRedeem objects loaded on page
  const [checklistOpen, setChecklistOpen] = useState(false); // boolean

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1000);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    const initializeTraining = async () => {
      const endpoint = `/api/v1/users/${userID}/trainings/e-ticketing/progress`
      await axios
        .get(endpoint)
        .then((response) => {
          setTrainingProgress(response.data.tasks);
        })
        .catch((error) => {
          console.log(error)
        })
    }
    initializeTraining();

    return () => {};
  }, []);

  const completeTask = async (taskKey) => {
    const endpoint = `/api/v1/users/${userID}/trainings/e-ticketing/complete_task`
    const requestBody = {
      key: taskKey,
      scanner_type: 'external',
      device_type: window.navigator.userAgent,
      interface_type: 'web',
      version: '1.0.0',
    }
  
    await axios
      .post(endpoint, requestBody)
      .then((response) => {
        setTrainingProgress(response.data.tasks);
      })
      .catch((error) => {
        console.log(error)
      })
  }

  console.log('contentKey: ', contentKey)
  return (
    (isSmallScreen || checklistOpen) ?
    <div className='wrapper-small-screen'>
      <TasksChecklist 
        trainingProgress={trainingProgress}
        contentKey={contentKey}
        setContentKey={setContentKey}
        isSmallScreen={isSmallScreen}
        checklistOpen={checklistOpen}
        setChecklistOpen={setChecklistOpen}
      />
      {!checklistOpen &&
        <Content 
          contentKey={contentKey}
          setContentKey={setContentKey}
          completeTask={completeTask}
          scans={scans}
          setScans={setScans}
          setChecklistOpen={setChecklistOpen}
        />}
    </div>
    :
    (
      <div className='wrapper-regular-screen'>
        <TasksChecklist 
          trainingProgress={trainingProgress}
          contentKey={contentKey}
          setContentKey={setContentKey}
          isSmallScreen={isSmallScreen}
          checklistOpen={checklistOpen}
          setChecklistOpen={setChecklistOpen}
        />
        <Content 
          contentKey={contentKey}
          setContentKey={setContentKey}
          completeTask={completeTask}
          scans={scans}
          setScans={setScans}
          setChecklistOpen={setChecklistOpen}
        />
      </div>
    )
  );
};

export default autoload(ETicketingTraining, 'eticketing-training');
