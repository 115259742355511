import MmenuLight from 'mmenu-light';

document.addEventListener('DOMContentLoaded', () => {
  const sticky = document.querySelector('.sticky');

  if (sticky) {
    const topOffset = sticky.offsetTop;
    window.onscroll = () => {
      if (window.pageYOffset != 0 && window.pageYOffset >= topOffset) {
        sticky.classList.add('fixed');
      } else {
        sticky.classList.remove('fixed');
      }
    };
  }
  const element = document.querySelector('#mobile-nav-menu');
  if (element) {
    const menu = new MmenuLight(element, '(max-width: 736px)');

    const navigator = menu.navigation({ title: '', theme: 'dark' });
    navigator.userAgent = 'not setting this breaks things';

    const drawer = menu.offcanvas({});

    const mobileNavToggle = document.getElementById('topnav-toggle-button');
    const toggleTopbar = document.querySelector('.toggle-topbar.menu-icon');

    let currentLevel = 0;

    function toggleMenu(e) {
      e.preventDefault();

      if (toggleTopbar && toggleTopbar.classList.contains('show-back-arrow')) {
        const panels = document.querySelectorAll('.mm-spn--open');

        if (panels.length > 1) {
          const currentPanel = panels[panels.length - 1];
          const parentUl = currentPanel.parentElement.closest('ul');

          if (parentUl) {
            navigator.openPanel(parentUl);
            currentLevel--;

            if (currentLevel === 0) {
              toggleTopbar.classList.remove('show-back-arrow');
            }
          }
        } else {
          toggleTopbar.classList.remove('show-back-arrow');
          currentLevel = 0;
          drawer.close();

          const mobileNavContainer = document.querySelector('.mm-ocd--left');
          if (mobileNavContainer) {
            mobileNavContainer.style.visibility = 'hidden';
          }
        }
      } else {
        const openElements = document.getElementsByClassName('mm-ocd--open');
        const mobileNavContainer = document.querySelector('.mm-ocd--left');

        if (openElements.length > 0) {
          drawer.close();
          if (mobileNavContainer) {
            mobileNavContainer.style.visibility = 'hidden';
          }
        } else {
          drawer.open();
          if (mobileNavContainer) {
            mobileNavContainer.style.visibility = 'visible';
          }
        }
      }
    }

    mobileNavToggle.onclick = toggleMenu;

    const nonDropdownItems = document.querySelectorAll(
      '#mobile-nav-menu li:not(.has-dropdown) > a',
    );
    nonDropdownItems.forEach(item => {
      item.addEventListener('click', function(event) {
        return true;
      });
    });

    const dropdownItems = document.querySelectorAll(
      '#mobile-nav-menu li.has-dropdown',
    );

    dropdownItems.forEach(item => {
      const link = item.querySelector('a');
      const dropdown = item.querySelector('.dropdown');

      if (link && dropdown) {
        link.addEventListener('click', function(event) {
          const mobileNavOpen = document.getElementsByClassName('mm-ocd--open');
          if (mobileNavOpen.length > 0) {
            const hasItems = dropdown.querySelectorAll('li').length > 1;

            if (hasItems) {
              navigator.openPanel(dropdown);
              currentLevel++;

              if (toggleTopbar) {
                toggleTopbar.classList.add('show-back-arrow');
              }

              event.preventDefault();
              event.stopPropagation();
            }
          }
        });
      }
    });

    document.addEventListener('click', function(event) {
      if (event.target.closest('.mm-spn--navbar') && event.clientX < 50) {
        if (currentLevel > 0) {
          currentLevel--;
        }

        if (currentLevel === 0 && toggleTopbar) {
          toggleTopbar.classList.remove('show-back-arrow');
        }
      }
    });

    const mobileNavContainer = document.querySelector('.mm-ocd--left');
    if (mobileNavContainer) {
      mobileNavContainer.style.visibility = 'hidden';
    }
  }
});
