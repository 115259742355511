import React from "react";

export default function ScanningForAllMarkup() {
  return (
    <div className="" id="page" style={{width: '100%'}}>
    <div className="page-title">Scan Badges for All Your Hosted Events</div>
    <div className="panel">
      <div
        data-props='{"hostId":24,"conId":3,"scanWindowOpen":true,"actionCableOn":true}'
        className="ticket-redemptions-component"
      >
        <div className="redemptions-container">
          <div id='scan-bar-anchor'>
            <div className="attendance-scan-bar attendance-scan-prompt">
              <span>Ready to scan a badge &nbsp;</span>
              <i className="fa fa-scanner fa-2x" title="Scan a badge" />
            </div>
          </div>
          <div id="Scanner" />
          <div className="scan-filter-bar">
            <div className="scan-filter-button">
              <span className="scan-filter-box">
                <i className="e-ticket-success-color fa fa-check fa-2x" />
                <div className="scan-filter-count">1</div>
              </span>
            </div>
            <div className="scan-filter-separator" />
            <div className="scan-filter-button">
              <span className="scan-filter-box">
                <i className="e-ticket-pending-color fa fa-refresh fa-2x" />
                <div className="scan-filter-count">1</div>
              </span>
            </div>
            <div className="scan-filter-separator" />
            <div className="scan-filter-button">
              <span className="scan-filter-box">
                <i className="e-ticket-attention-color fa fa-alert fa-2x" />
                <div className="scan-filter-count">3</div>
              </span>
            </div>
            <div className="scan-filter-separator" />
            <div className="scan-filter-button">
              <span className="scan-filter-box">
                <i className="e-ticket-error-color fa fa-exclamation fa-2x" />
                <div className="scan-filter-count">1</div>
              </span>
            </div>
          </div>
          <div className="infinite-scroll-component__outerdiv">
            <div
              className="infinite-scroll-component "
              style={{ height: "auto", overflow: "auto" }}
            >
              <div>
                <table className="scan-ticket-table">
                  <tbody style={{ columnCount: 5 }}>
                    <tr className="scan-filter-row">
                      <td style={{ width: "80%" }}>
                        <span className="ticketholder-name">
                          <div>Flora D'Allie</div>
                          <div className="message">Select an event to redeem</div>
                          <div className="event-result-container">
                            <div className="event-icon text-center" />
                          </div>
                        </span>
                      </td>
                      <td
                        style={{
                          width: "20%",
                          textAlign: "right",
                          paddingRight: "1em"
                        }}
                      >
                        <span className="ticket-count-container">
                          <div className="ticket-count-num" />
                          <i className="fa e-ticket-attention-color fa-no-ticket fa-3x" />
                        </span>
                      </td>
                    </tr>
                    <tr className="scan-filter-row">
                      <td style={{ width: "80%" }}>
                        <span className="ticketholder-name">
                          <div>Clyde Guzuta</div>
                          <div className="message">
                            Attendee does not have a badge with access to this
                            event
                          </div>
                          <div className="event-result-container">
                            <div className="event-icon text-center">
                              <img
                                alt="icon"
                                border={0}
                                src="/event_types/WebRPG100px_1.gif"
                              />
                            </div>
                            <div className="message event-title">RPG Event 1</div>
                            <div className="message event-code">
                              {" "}
                              RPG24ND00014
                            </div>
                          </div>
                        </span>
                      </td>
                      <td
                        style={{
                          width: "20%",
                          textAlign: "right",
                          paddingRight: "1em"
                        }}
                      >
                        <span className="ticket-count-container">
                          <div className="ticket-count-num" />
                          <i className="fa e-ticket-error-color fa-badge-error fa-3x" />
                        </span>
                      </td>
                    </tr>
                    <tr className="scan-filter-row">
                      <td style={{ width: "80%" }}>
                        <span className="ticketholder-name">
                          <div>Casper McFadden</div>
                          <div className="message">
                            No tickets found - select for options
                          </div>
                          <div className="event-result-container">
                            <div className="event-icon text-center">
                              <img
                                alt="icon"
                                border={0}
                                src="/event_types/WebRPG100px_1.gif"
                              />
                            </div>
                            <div className="message event-title">RPG Event 1</div>
                            <div className="message event-code">
                              {" "}
                              RPG24ND00014
                            </div>
                          </div>
                        </span>
                      </td>
                      <td
                        style={{
                          width: "20%",
                          textAlign: "right",
                          paddingRight: "1em"
                        }}
                      >
                        <span className="ticket-count-container">
                          <div className="ticket-count-num" />
                          <i className="fa e-ticket-attention-color fa-no-ticket fa-3x" />
                        </span>
                      </td>
                    </tr>
                    <tr className="scan-filter-row">
                      <td style={{ width: "80%" }}>
                        <span className="ticketholder-name">
                          <div>Ingrid Moons</div>
                          <div className="message">
                            Ticket transaction awaiting approval by Ingrid Moons
                          </div>
                          <div className="event-result-container">
                            <div className="event-icon text-center">
                              <img
                                alt="icon"
                                border={0}
                                src="/event_types/WebRPG100px_1.gif"
                              />
                            </div>
                            <div className="message event-title">RPG Event 1</div>
                            <div className="message event-code">
                              {" "}
                              RPG24ND00014
                            </div>
                          </div>
                        </span>
                      </td>
                      <td
                        style={{
                          width: "20%",
                          textAlign: "right",
                          paddingRight: "1em"
                        }}
                      >
                        <span className="ticket-count-container">
                          <div className="ticket-count-num" />
                          <i className="fa e-ticket-pending-color fa-ticket-in-cart fa-3x" />
                        </span>
                      </td>
                    </tr>
                    <tr className="scan-filter-row">
                      <td style={{ width: "80%" }}>
                        <span className="ticketholder-name">
                          <div>Allie Durango</div>
                          <div className="message">
                            Allie has 1 guest ticket remaining. Select to scan
                            guests.
                          </div>
                          <div className="event-result-container">
                            <div className="event-icon text-center">
                              <img
                                alt="icon"
                                border={0}
                                src="/event_types/WebRPG100px_1.gif"
                              />
                            </div>
                            <div className="message event-title">RPG Event 1</div>
                            <div className="message event-code">
                              {" "}
                              RPG24ND00014
                            </div>
                          </div>
                        </span>
                      </td>
                      <td
                        style={{
                          width: "20%",
                          textAlign: "right",
                          paddingRight: "1em"
                        }}
                      >
                        <span className="ticket-count-container">
                          <div className="ticket-count-num">+1</div>
                          <i className="fa e-ticket-attention-color fa-ticket-horz-blank fa-3x" />
                        </span>
                      </td>
                    </tr>
                    <tr className="scan-filter-row">
                      <td style={{ width: "80%" }}>
                        <span className="ticketholder-name">
                          <div>Fred D'Allie</div>
                          <div className="event-result-container">
                            <div className="event-icon text-center">
                              <img
                                alt="icon"
                                border={0}
                                src="/event_types/WebRPG100px_1.gif"
                              />
                            </div>
                            <div className="message event-title">RPG Event 1</div>
                            <div className="message event-code">
                              {" "}
                              RPG24ND00014
                            </div>
                          </div>
                        </span>
                      </td>
                      <td
                        style={{
                          width: "20%",
                          textAlign: "right",
                          paddingRight: "1em"
                        }}
                      >
                        <span className="ticket-count-container">
                          <div className="ticket-count-num" />
                          <i className="fa e-ticket-success-color fa-ticket-checked fa-3x" />
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )};