import React, { useEffect, useState, useRef } from 'react';
import Tippy from '@tippyjs/react';

const uninteractableStyles = {
  pointerEvents: 'none',
  userSelect: 'none',
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  msUserSelect: 'none',
  cursor: 'default',
};

const interactableStyles = { 
  pointerEvents: 'auto',
  userSelect: 'auto',
  WebkitUserSelect: 'auto',
  MozUserSelect: 'auto',
  msUserSelect: 'auto',
  cursor: 'pointer',
};

const MarkupTooltip = ({ 
  markup,
  selector,
  placement,
  content,
  onClick,
  buttonText,
  altButtonText,
  altOnClick,
  icon,
  raw, 
  disable=true
}) => {
  const containerRef = useRef(null);
  const targetRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let timeoutId;

    const findAndSetupTarget = () => {
      if (!containerRef.current || !selector) return;

      const element = containerRef.current.querySelector(selector);
      if (element) {
        console.log('Found target element:', selector);
        targetRef.current = element;
        setIsVisible(true);
      } else {
        console.log('No element found matching selector:', selector);
      }
    };

    // Initial setup
    timeoutId = setTimeout(findAndSetupTarget, 100);

    // Cleanup
    return () => {
      clearTimeout(timeoutId);
      if (targetRef.current && disable) {
        Object.assign(targetRef.current.style, uninteractableStyles);
      }
    };
  }, [selector]);

  const handleClick = (handler) => {
    setIsVisible(false);
    if (targetRef.current && disable) {
      Object.assign(targetRef.current.style, uninteractableStyles);
    }
    if (handler) {
      handler();
    }
  };

  return (
    <div ref={containerRef}>
      <div style={disable ? uninteractableStyles : interactableStyles}>{markup}</div>
      {targetRef.current && (
        <Tippy
          content={
            raw ?
              <div style={{textAlign: 'left'}}>
                {raw}
                <div className='next-button-row' style={{display: 'flex', justifyContent: altOnClick ? 'space-between' : 'end'}}>
                {(altButtonText && <div className='banner-button back-button' style={interactableStyles} onClick={altOnClick}>{altButtonText}</div>)}
                {(buttonText && <div className='banner-button next-button' style={interactableStyles} onClick={onClick}>{buttonText}</div>)}
                </div>
              </div>
              :
              <div style={interactableStyles}>
                <p className='tooltip-content-p' style={{fontWeight: 'normal', textAlign: 'left'}}>{content}</p>
                {icon && <i className={icon}></i>}
                <div className='next-button-row' style={{display: 'flex', justifyContent: altOnClick ? 'space-between' : 'end'}}>
                {(altButtonText && <div className='banner-button back-button' style={interactableStyles} onClick={altOnClick}>{altButtonText}</div>)}
                {(buttonText && <div className='banner-button next-button' style={interactableStyles} onClick={onClick}>{buttonText}</div>)}
                </div>
              </div>
          }
          reference={targetRef}
          visible={true} interactive={true} placement={placement}
          popperOptions={{
            strategy: 'fixed',
            modifiers: [
              {
                name: 'flip',
                options: {
                  fallbackPlacements: ['left', 'right'],
                },
              },
            ],
          }}
        />
      )}
    </div>
  );
};

export default MarkupTooltip;