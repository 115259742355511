import React, { Component } from 'react';
import PaymentMethodsTable from './PaymentMethodsTable';

class PaymentMethodsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      admin: props.admin,
      displayNew: props.displayNew,
    };
  }

  render() {
    const { contact_id } = this.props.match.params;
    const { displayNew } = this.state;

    return (
      <div class="panel">
        <div className="ribbon-top">
          <ul className="left">
            {displayNew && (
              <li className="btn">
                <a href={`/contacts/${contact_id}/payment_methods/new`}>
                  <i className="fa fa-plus-circle fa-2x"></i>
                  <label>New Payment Method</label>
                </a>
              </li>
            )}
          </ul>
        </div>

        <PaymentMethodsTable
          contactId={this.props.match.params.contact_id}
          showStateColumn={this.state.admin}
          showDetailsLink={this.state.admin}
        />
      </div>
    );
  }
}

export default PaymentMethodsPage;
