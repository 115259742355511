import React from "react";

export default function MyScheduleMarkup() {
return (
  <div className="" id="page" style={{width: '100%'}}>
  <div className="page-title">
    Gm 1's
    <span className="user-homepage">Home Page</span>
    <span className="user-schedule">Schedule</span>
  </div>
  <div className="panel hidden-for-small">
    <h2 style={{ padding: 10 }}>
      Congratulations, you have a badge for Gen Con Indy 2025!
    </h2>
    <p>
      Visit your
      <a href="/packets/24">Packets Page</a>
      to see how you will receive your badge and tickets.
    </p>
    <p>
      Visit your
      <a href="/associations/24">Friends &amp; Family Page</a>
      to access their homepages and see what events they have tickets for.
    </p>
  </div>
  <div className="panel hidden-for-small">
    <div className="ribbon-top">
      <div className="panel_titlebar">Badge</div>
    </div>
    <table
      cellPadding={5}
      className="records"
      style={{ width: "100%", margin: 0 }}
    >
      <tbody>
        <tr>
          <td>
            <b>You have a 4-Day badge</b>
          </td>
          <td style={{ textAlign: "right" }}>
            <a className="dropdown_switch" data="badge_1_info" href="#">
              Badge Information
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <div id="badge_1_info" style={{ display: "none", paddingBottom: 10 }}></div>
  </div>
  <div className="panel">
    <div className="ribbon-top">
      <div className="panel_titlebar">My Schedule</div>
      <div className="text-center" id="return-info" style={{ display: "none" }}>
        <br />
        Select tickets and click CONFIRM to return.
        <div className="refund_total">
          Total amount to be refunded to system credit: $0.00
        </div>
        <div className="ital bolded_red">
          (System credit is non-refundable, non-transferrable and does not
          expire.)
        </div>
      </div>
      <div className="flex-row flex-space-between">
        <p>
          For more information on electronic tickets, please visit the
          <a href="https://www.gencon.com/attend/e-ticketing">
            Attendee Guide - E-Ticketing
          </a>
        </p>
        <div>
          <a className="button" href="/users/24/ticket_returns/new">
            Return Tickets
          </a>
          &nbsp;
        </div>
      </div>
    </div>
    <div className="ribbon-row">
      <ul className="left">
        <li className="btn" id='scan-for-all-button'>
          <a href="/schedules/24/host_scanning">
            <i className="fa fa-scanner fa-2x"></i>
            <label>Scan For All My Events</label>
          </a>
        </li>
      </ul>
    </div>
    <div
      data-props='{"contactId":24,"c":"indy2025"}'
      className="schedule-component"
    >
      <div className="finder">
        <div className="finder-filters">
          <div className="finder-filter-link" />
          <button
            type="button"
            className="search-filter button disabled"
            title="Filter Search"
            disabled=""
          >
            <i className="fa fa-filter fa-2x" />
          </button>
        </div>
        <div className="search-area">
          <form role="search" className="form_menu">
            <input
              type="text"
              name="search"
              id="search"
              className="event-finder-search"
              placeholder="Events loading...hang on..."
              disabled=""
              defaultValue=""
            />
          </form>
        </div>
        <div className="schedule-header">
          <div>&nbsp;</div>
          <div>Title</div>
          <div>Time / Location</div>
          <div>Tickets</div>
        </div>
        <div className="schedule-items" id='schedule-items-anchor'>
          <div>
            <a href="/events/14">
              <div className="ticket_in_cart schedule-item even">
                <div className="row show-for-small">
                  <div className="event-title">
                    <div>RPG Event 1</div>
                  </div>
                  <div className="subtitle">
                    <div className="small">Yes, Current Edition Edition</div>
                  </div>
                </div>
                <div className="event-icon text-center">
                  <img
                    alt="icon"
                    border={0}
                    height={40}
                    src="/event_types/WebHOST100px_1.png"
                    width={40}
                  />
                </div>
                <div className="event-detail hide-for-small">
                  <div className="event-title">
                    <div>RPG Event 1</div>
                  </div>
                  <div className="subtitle">
                    <div className="small">Yes, Current Edition Edition</div>
                  </div>
                </div>
                <div className="event-time-location">
                  <div className="tl-icon">
                    <i className="fa fa-clock-o" />
                  </div>
                  <div className="tl-text">Thu at 8:00 am EST for 1 hr</div>
                  <hr style={{ margin: 0 }} />
                  <div className="tl-icon">
                    <i className="fa fa-map-marker" />
                  </div>
                  <div className="tl-text" />
                </div>
                <div className="event-tickets">
                  <div className="ticket-count">
                    <div>Sold: 7</div>
                    <div>Max: 10</div>
                  </div>
                  <div className="ticket-type-icon">
                    <i
                      className="fa fa-ticket-e fa-2x"
                      title="Electronic Ticket"
                    />
                  </div>
                </div>
              </div>
            </a>
            <a href="/events/6">
              <div className="ticket_in_cart schedule-item odd">
                <div className="row show-for-small">
                  <div className="event-title">
                    <div>True Dungeon Event 1</div>
                  </div>
                  <div className="subtitle" />
                </div>
                <div className="event-icon text-center">
                  <img
                    alt="icon"
                    border={0}
                    height={40}
                    src="/event_types/WebHOST100px_1.png"
                    width={40}
                  />
                </div>
                <div className="event-detail hide-for-small">
                  <div className="event-title">
                    <div>True Dungeon Event 1</div>
                  </div>
                  <div className="subtitle" />
                </div>
                <div className="event-time-location">
                  <div className="tl-icon">
                    <i className="fa fa-clock-o" />
                  </div>
                  <div className="tl-text">Thu at 9:00 am EST for 1 hr</div>
                  <hr style={{ margin: 0 }} />
                  <div className="tl-icon">
                    <i className="fa fa-map-marker" />
                  </div>
                  <div className="tl-text" />
                </div>
                <div className="event-tickets">
                  <div className="ticket-count">
                    <div>Sold: 7</div>
                    <div>Max: 10</div>
                  </div>
                  <div className="ticket-type-icon">
                    <i
                      className="fa fa-ticket-e fa-2x"
                      title="Electronic Ticket"
                    />
                  </div>
                </div>
              </div>
            </a>
            <a href="/events/15">
              <div className="ticket_in_cart schedule-item even">
                <div className="row show-for-small">
                  <div className="event-title">
                    <div>RPG Event 2</div>
                  </div>
                  <div className="subtitle">
                    <div className="small">Yes, Current Edition Edition</div>
                  </div>
                </div>
                <div className="event-icon text-center">
                  <img
                    alt="icon"
                    border={0}
                    height={40}
                    src="/event_types/WebRPG100px_1.gif"
                    width={40}
                  />
                </div>
                <div className="event-detail hide-for-small">
                  <div className="event-title">
                    <div>RPG Event 2</div>
                  </div>
                  <div className="subtitle">
                    <div className="small">Yes, Current Edition Edition</div>
                  </div>
                </div>
                <div className="event-time-location">
                  <div className="tl-icon">
                    <i className="fa fa-clock-o" />
                  </div>
                  <div className="tl-text">Thu at 7:00 am EST for 1 hr</div>
                  <hr style={{ margin: 0 }} />
                  <div className="tl-icon">
                    <i className="fa fa-map-marker" />
                  </div>
                  <div className="tl-text" />
                </div>
                <div className="event-tickets">
                  <div className="ticket-count">
                    <div>Me: 1</div>
                    <div />
                  </div>
                  <div className="ticket-type-icon">
                    <i
                      className="fa fa-ticket-e fa-2x"
                      title="Electronic Ticket"
                    />
                  </div>
                </div>
              </div>
            </a>
            <a href="/events/22">
              <div className="ticket_in_cart schedule-item odd">
                <div className="row show-for-small">
                  <div className="event-title">
                    <div>Board Game Event 1</div>
                  </div>
                  <div className="subtitle">
                    <div className="small">Yes, Current Edition Edition</div>
                  </div>
                </div>
                <div className="event-icon text-center">
                  <img
                    alt="icon"
                    border={0}
                    height={40}
                    src="/event_types/WebHOST100px_1.png"
                    width={40}
                  />
                </div>
                <div className="event-detail hide-for-small">
                  <div className="event-title">
                    <div>Board Game Event 1</div>
                  </div>
                  <div className="subtitle">
                    <div className="small">Yes, Current Edition Edition</div>
                  </div>
                </div>
                <div className="event-time-location">
                  <div className="tl-icon">
                    <i className="fa fa-clock-o" />
                  </div>
                  <div className="tl-text">Thu at 1:00 pm EST for 1 min</div>
                  <hr style={{ margin: 0 }} />
                  <div className="tl-icon">
                    <i className="fa fa-map-marker" />
                  </div>
                  <div className="tl-text" />
                </div>
                <div className="event-tickets">
                  <div className="ticket-count">
                    <div>Sold: 5</div>
                    <div>Max: 20</div>
                  </div>
                  <div className="ticket-type-icon">
                    <i
                      className="fa fa-ticket-horz fa-2x"
                      title="Paper Ticket"
                    />
                  </div>
                </div>
              </div>
            </a>
            <a href="/events/16">
              <div className="ticket_in_cart schedule-item even">
                <div className="row show-for-small">
                  <div className="event-title">
                    <div>RPG Event 3</div>
                  </div>
                  <div className="subtitle">
                    <div className="small">Yes, Current Edition Edition</div>
                  </div>
                </div>
                <div className="event-icon text-center">
                  <img
                    alt="icon"
                    border={0}
                    height={40}
                    src="/event_types/WebRPG100px_1.gif"
                    width={40}
                  />
                </div>
                <div className="event-detail hide-for-small">
                  <div className="event-title">
                    <div>RPG Event 3</div>
                  </div>
                  <div className="subtitle">
                    <div className="small">Yes, Current Edition Edition</div>
                  </div>
                </div>
                <div className="event-time-location">
                  <div className="tl-icon">
                    <i className="fa fa-clock-o" />
                  </div>
                  <div className="tl-text">Fri at 5:00 am EST for 1 hr</div>
                  <hr style={{ margin: 0 }} />
                  <div className="tl-icon">
                    <i className="fa fa-map-marker" />
                  </div>
                  <div className="tl-text" />
                </div>
                <div className="event-tickets">
                  <div className="ticket-count">
                    <div>Me: 1</div>
                    <div />
                  </div>
                  <div className="ticket-type-icon">
                    <i
                      className="fa fa-ticket-e fa-2x"
                      title="Electronic Ticket"
                    />
                  </div>
                </div>
              </div>
            </a>
            <a href="/events/17">
              <div className="ticket_in_cart schedule-item odd">
                <div className="row show-for-small">
                  <div className="event-title">
                    <div>RPG Event 4</div>
                  </div>
                  <div className="subtitle">
                    <div className="small">Yes, Current Edition Edition</div>
                  </div>
                </div>
                <div className="event-icon text-center">
                  <img
                    alt="icon"
                    border={0}
                    height={40}
                    src="/event_types/WebRPG100px_1.gif"
                    width={40}
                  />
                </div>
                <div className="event-detail hide-for-small">
                  <div className="event-title">
                    <div>RPG Event 4</div>
                  </div>
                  <div className="subtitle">
                    <div className="small">Yes, Current Edition Edition</div>
                  </div>
                </div>
                <div className="event-time-location">
                  <div className="tl-icon">
                    <i className="fa fa-clock-o" />
                  </div>
                  <div className="tl-text">Fri at 7:00 am EST for 1 hr</div>
                  <hr style={{ margin: 0 }} />
                  <div className="tl-icon">
                    <i className="fa fa-map-marker" />
                  </div>
                  <div className="tl-text" />
                </div>
                <div className="event-tickets">
                  <div className="ticket-count">
                    <div>Me: 1</div>
                    <div />
                  </div>
                  <div className="ticket-type-icon">
                    <i
                      className="fa fa-ticket-e fa-2x"
                      title="Electronic Ticket"
                    />
                  </div>
                </div>
              </div>
            </a>
            <a href="/events/18">
              <div className="ticket_in_cart schedule-item even">
                <div className="row show-for-small">
                  <div className="event-title">
                    <div>RPG Event 5</div>
                  </div>
                  <div className="subtitle">
                    <div className="small">Yes, Current Edition Edition</div>
                  </div>
                </div>
                <div className="event-icon text-center">
                  <img
                    alt="icon"
                    border={0}
                    height={40}
                    src="/event_types/WebHOST100px_1.png"
                    width={40}
                  />
                </div>
                <div className="event-detail hide-for-small">
                  <div className="event-title">
                    <div>RPG Event 5</div>
                  </div>
                  <div className="subtitle">
                    <div className="small">Yes, Current Edition Edition</div>
                  </div>
                </div>
                <div className="event-time-location">
                  <div className="tl-icon">
                    <i className="fa fa-clock-o" />
                  </div>
                  <div className="tl-text">Sat at 8:00 am EST for 1 hr</div>
                  <hr style={{ margin: 0 }} />
                  <div className="tl-icon">
                    <i className="fa fa-map-marker" />
                  </div>
                  <div className="tl-text" />
                </div>
                <div className="event-tickets">
                  <div className="ticket-count">
                    <div>Sold: 6</div>
                    <div>Max: 10</div>
                  </div>
                  <div className="ticket-type-icon">
                    <i
                      className="fa fa-ticket-e fa-2x"
                      title="Electronic Ticket"
                    />
                  </div>
                </div>
              </div>
            </a>
            <a href="/events/10">
              <div className="ticket_in_cart schedule-item odd">
                <div className="row show-for-small">
                  <div className="event-title">
                    <div>True Dungeon Event 5</div>
                  </div>
                  <div className="subtitle" />
                </div>
                <div className="event-icon text-center">
                  <img
                    alt="icon"
                    border={0}
                    height={40}
                    src="/event_types/WebHOST100px_1.png"
                    width={40}
                  />
                </div>
                <div className="event-detail hide-for-small">
                  <div className="event-title">
                    <div>True Dungeon Event 5</div>
                  </div>
                  <div className="subtitle" />
                </div>
                <div className="event-time-location">
                  <div className="tl-icon">
                    <i className="fa fa-clock-o" />
                  </div>
                  <div className="tl-text">Sat at 9:00 am EST for 1 hr</div>
                  <hr style={{ margin: 0 }} />
                  <div className="tl-icon">
                    <i className="fa fa-map-marker" />
                  </div>
                  <div className="tl-text" />
                </div>
                <div className="event-tickets">
                  <div className="ticket-count">
                    <div>Sold: 6</div>
                    <div>Max: 10</div>
                  </div>
                  <div className="ticket-type-icon">
                    <i
                      className="fa fa-ticket-e fa-2x"
                      title="Electronic Ticket"
                    />
                  </div>
                </div>
              </div>
            </a>
            <a href="/events/19">
              <div className="ticket_in_cart schedule-item even">
                <div className="row show-for-small">
                  <div className="event-title">
                    <div>RPG Event 6</div>
                  </div>
                  <div className="subtitle">
                    <div className="small">Yes, Current Edition Edition</div>
                  </div>
                </div>
                <div className="event-icon text-center">
                  <img
                    alt="icon"
                    border={0}
                    height={40}
                    src="/event_types/WebHOST100px_1.png"
                    width={40}
                  />
                </div>
                <div className="event-detail hide-for-small">
                  <div className="event-title">
                    <div>RPG Event 6</div>
                  </div>
                  <div className="subtitle">
                    <div className="small">Yes, Current Edition Edition</div>
                  </div>
                </div>
                <div className="event-time-location">
                  <div className="tl-icon">
                    <i className="fa fa-clock-o" />
                  </div>
                  <div className="tl-text">Sat at 10:00 am EST for 1 hr</div>
                  <hr style={{ margin: 0 }} />
                  <div className="tl-icon">
                    <i className="fa fa-map-marker" />
                  </div>
                  <div className="tl-text" />
                </div>
                <div className="event-tickets">
                  <div className="ticket-count">
                    <div>Sold: 6</div>
                    <div>Max: 10</div>
                  </div>
                  <div className="ticket-type-icon">
                    <i
                      className="fa fa-ticket-e fa-2x"
                      title="Electronic Ticket"
                    />
                  </div>
                </div>
              </div>
            </a>
            <a href="/events/11">
              <div className="ticket_in_cart schedule-item odd">
                <div className="row show-for-small">
                  <div className="event-title">
                    <div>True Dungeon Event 6</div>
                  </div>
                  <div className="subtitle" />
                </div>
                <div className="event-icon text-center">
                  <img
                    alt="icon"
                    border={0}
                    height={40}
                    src="/event_types/WebHOST100px_1.png"
                    width={40}
                  />
                </div>
                <div className="event-detail hide-for-small">
                  <div className="event-title">
                    <div>True Dungeon Event 6</div>
                  </div>
                  <div className="subtitle" />
                </div>
                <div className="event-time-location">
                  <div className="tl-icon">
                    <i className="fa fa-clock-o" />
                  </div>
                  <div className="tl-text">Sat at 11:00 am EST for 1 hr</div>
                  <hr style={{ margin: 0 }} />
                  <div className="tl-icon">
                    <i className="fa fa-map-marker" />
                  </div>
                  <div className="tl-text" />
                </div>
                <div className="event-tickets">
                  <div className="ticket-count">
                    <div>Sold: 6</div>
                    <div>Max: 10</div>
                  </div>
                  <div className="ticket-type-icon">
                    <i
                      className="fa fa-ticket-e fa-2x"
                      title="Electronic Ticket"
                    />
                  </div>
                </div>
              </div>
            </a>
            <a href="/events/23">
              <div className="ticket_in_cart schedule-item even">
                <div className="row show-for-small">
                  <div className="event-title">
                    <div>Introduction to Adventuring - eTicketing Edition</div>
                  </div>
                  <div className="subtitle">
                    <div className="small">Yes, Current Edition Edition</div>
                  </div>
                </div>
                <div className="event-icon text-center">
                  <img
                    alt="icon"
                    border={0}
                    height={40}
                    src="/event_types/WebBGM100px_1.gif"
                    width={40}
                  />
                </div>
                <div className="event-detail hide-for-small">
                  <div className="event-title">
                    <div>Introduction to Adventuring - eTicketing Edition</div>
                  </div>
                  <div className="subtitle">
                    <div className="small">Yes, Current Edition Edition</div>
                  </div>
                </div>
                <div className="event-time-location">
                  <div className="tl-icon">
                    <i className="fa fa-clock-o" />
                  </div>
                  <div className="tl-text">Sun at 12:00 am EST for 1 hr</div>
                  <hr style={{ margin: 0 }} />
                  <div className="tl-icon">
                    <i className="fa fa-map-marker" />
                  </div>
                  <div className="tl-text" />
                </div>
                <div className="event-tickets">
                  <div className="ticket-count">
                    <div>Me: 1</div>
                    <div />
                  </div>
                  <div className="ticket-type-icon">
                    <i
                      className="fa fa-ticket-e fa-2x"
                      title="Electronic Ticket"
                    />
                  </div>
                </div>
              </div>
            </a>
            <a href="/events/20">
              <div className="ticket_in_cart schedule-item odd">
                <div className="row show-for-small">
                  <div className="event-title">
                    <div>RPG Event 7</div>
                  </div>
                  <div className="subtitle">
                    <div className="small">Yes, Current Edition Edition</div>
                  </div>
                </div>
                <div className="event-icon text-center">
                  <img
                    alt="icon"
                    border={0}
                    height={40}
                    src="/event_types/WebHOST100px_1.png"
                    width={40}
                  />
                </div>
                <div className="event-detail hide-for-small">
                  <div className="event-title">
                    <div>RPG Event 7</div>
                  </div>
                  <div className="subtitle">
                    <div className="small">Yes, Current Edition Edition</div>
                  </div>
                </div>
                <div className="event-time-location">
                  <div className="tl-icon">
                    <i className="fa fa-clock-o" />
                  </div>
                  <div className="tl-text">Sun at 8:00 am EST for 1 hr</div>
                  <hr style={{ margin: 0 }} />
                  <div className="tl-icon">
                    <i className="fa fa-map-marker" />
                  </div>
                  <div className="tl-text" />
                </div>
                <div className="event-tickets">
                  <div className="ticket-count">
                    <div>Sold: 6</div>
                    <div>Max: 10</div>
                  </div>
                  <div className="ticket-type-icon">
                    <i
                      className="fa fa-ticket-e fa-2x"
                      title="Electronic Ticket"
                    />
                  </div>
                </div>
              </div>
            </a>
            <a href="/events/12">
              <div className="ticket_in_cart schedule-item even">
                <div className="row show-for-small">
                  <div className="event-title">
                    <div>True Dungeon Event 7</div>
                  </div>
                  <div className="subtitle" />
                </div>
                <div className="event-icon text-center">
                  <img
                    alt="icon"
                    border={0}
                    height={40}
                    src="/event_types/WebHOST100px_1.png"
                    width={40}
                  />
                </div>
                <div className="event-detail hide-for-small">
                  <div className="event-title">
                    <div>True Dungeon Event 7</div>
                  </div>
                  <div className="subtitle" />
                </div>
                <div className="event-time-location">
                  <div className="tl-icon">
                    <i className="fa fa-clock-o" />
                  </div>
                  <div className="tl-text">Sun at 9:00 am EST for 1 hr</div>
                  <hr style={{ margin: 0 }} />
                  <div className="tl-icon">
                    <i className="fa fa-map-marker" />
                  </div>
                  <div className="tl-text" />
                </div>
                <div className="event-tickets">
                  <div className="ticket-count">
                    <div>Sold: 6</div>
                    <div>Max: 10</div>
                  </div>
                  <div className="ticket-type-icon">
                    <i
                      className="fa fa-ticket-e fa-2x"
                      title="Electronic Ticket"
                    />
                  </div>
                </div>
              </div>
            </a>
            <a href="/events/21">
              <div className="ticket_in_cart schedule-item odd">
                <div className="row show-for-small">
                  <div className="event-title">
                    <div>RPG Event 8</div>
                  </div>
                  <div className="subtitle">
                    <div className="small">Yes, Current Edition Edition</div>
                  </div>
                </div>
                <div className="event-icon text-center">
                  <img
                    alt="icon"
                    border={0}
                    height={40}
                    src="/event_types/WebHOST100px_1.png"
                    width={40}
                  />
                </div>
                <div className="event-detail hide-for-small">
                  <div className="event-title">
                    <div>RPG Event 8</div>
                  </div>
                  <div className="subtitle">
                    <div className="small">Yes, Current Edition Edition</div>
                  </div>
                </div>
                <div className="event-time-location">
                  <div className="tl-icon">
                    <i className="fa fa-clock-o" />
                  </div>
                  <div className="tl-text">Sun at 10:00 am EST for 1 hr</div>
                  <hr style={{ margin: 0 }} />
                  <div className="tl-icon">
                    <i className="fa fa-map-marker" />
                  </div>
                  <div className="tl-text" />
                </div>
                <div className="event-tickets">
                  <div className="ticket-count">
                    <div>Sold: 6</div>
                    <div>Max: 10</div>
                  </div>
                  <div className="ticket-type-icon">
                    <i
                      className="fa fa-ticket-e fa-2x"
                      title="Electronic Ticket"
                    />
                  </div>
                </div>
              </div>
            </a>
            <a href="/events/13">
              <div className="ticket_in_cart schedule-item even">
                <div className="row show-for-small">
                  <div className="event-title">
                    <div>True Dungeon Event 8</div>
                  </div>
                  <div className="subtitle" />
                </div>
                <div className="event-icon text-center">
                  <img
                    alt="icon"
                    border={0}
                    height={40}
                    src="/event_types/WebHOST100px_1.png"
                    width={40}
                  />
                </div>
                <div className="event-detail hide-for-small">
                  <div className="event-title">
                    <div>True Dungeon Event 8</div>
                  </div>
                  <div className="subtitle" />
                </div>
                <div className="event-time-location">
                  <div className="tl-icon">
                    <i className="fa fa-clock-o" />
                  </div>
                  <div className="tl-text">Sun at 11:00 am EST for 1 hr</div>
                  <hr style={{ margin: 0 }} />
                  <div className="tl-icon">
                    <i className="fa fa-map-marker" />
                  </div>
                  <div className="tl-text" />
                </div>
                <div className="event-tickets">
                  <div className="ticket-count">
                    <div>Sold: 6</div>
                    <div>Max: 10</div>
                  </div>
                  <div className="ticket-type-icon">
                    <i
                      className="fa fa-ticket-e fa-2x"
                      title="Electronic Ticket"
                    />
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
)};