import React from 'react';
import Banner from './Banner';
import Modal from './Modal';
import DemoTicketRedemptions from '../ticketRedemption/DemoTicketRedemptions';
import NavButtons from './NavButtons';
import ScreenCaptureTooltipView from './ScreenCaptureTooltipView';
import ScreenCaptureBannerView from './ScreenCaptureBannerView';
import ScanningTooltipView from './ScanningTooltipView';
import MarkupTooltip from './MarkupTooltip';
import MarkupBanner from './MarkupBanner';
import ScanningForAllMarkup from './markups/ScanningForAllMarkup';
import ScanningForAllModalMarkup from './markups/ScanningForAllModalMarkup';
import ScanningScreen5Badges from './markups/ScanningScreen5Badges';
import MyScheduleMarkup from './markups/MyScheduleMarkup';


const SectionTitle = ({ title }) => {
  return (
    <div className='section-title-wrapper'>
      <h2 className='section-title'>{title}</h2>
    </div>
  )
}

const Content = ({ contentKey, setContentKey, completeTask, scans, setScans, isMobileScreen, setChecklistOpen }) => {

  const badgeCodes = [
    { id: 1, badge_code: 'B-96JDLZ88GDEY', state: 'redeemed', message: '', ticketholder: { first_name: 'Fred', last_name: 'One-Tic' }, event_choices: [], additional_tickets: 0, updated_at: '2020-01-01T00:00:00.000Z' },
    { id: 2, badge_code: 'B-YWG5L1EEWPQN', state: 'redeemed', message: '', ticketholder: { first_name: 'Flora', last_name: 'One-Tic' }, event_choices: [], additional_tickets: 0, updated_at: '2020-01-01T00:00:00.000Z' },
    { id: 3, badge_code: 'B-9ER5QZ771RRN', state: 'partially_redeemed', message: 'Allie has guest tickets remaining - select to scan', ticketholder: { first_name: 'Allie', last_name: 'Three-Tix' }, event_choices: [], additional_tickets: 2, updated_at: '2020-01-01T00:00:00.000Z' },
    { id: 4, badge_code: 'B-NQQ3VD88GQPN', state: '', message: '', ticketholder: { first_name: 'Julie', last_name: 'Guest' }, event_choices: [], additional_tickets: 0, updated_at: '2020-01-01T00:00:00.000Z' },
    { id: 5, badge_code: 'B-NVMGLWPPOMRN', state: '', message: '', ticketholder: { first_name: 'Malcolm', last_name: 'Guest' }, event_choices: [], additional_tickets: 0, updated_at: '2020-01-01T00:00:00.000Z' },
    { id: 6, badge_code: 'B-Y80KZWJJ8019', state: '', message: '', ticketholder: { first_name: 'Casper', last_name: 'No-Tix' }, event_choices: [], additional_tickets: 0, updated_at: '2020-01-01T00:00:00.000Z' },
    { id: 7, badge_code: 'B-9M0EKVPP30LN', state: '', message: '', ticketholder: { first_name: 'Ingrid', last_name: 'No-Tix' }, event_choices: [], additional_tickets: 0, updated_at: '2020-01-01T00:00:00.000Z' },
    { id: 8, badge_code: 'B-Y0R5G811ERE9', state: 'badge_error', message: 'Attendee does not have a badge with access to this event', ticketholder: { first_name: 'Clyde', last_name: 'Bad-Badge' }, event_choices: [], additional_tickets: 0, updated_at: '2020-01-01T00:00:00.000Z' },
  ]


  const content = {
    'web1.1': (
      <div className='content'>
        <SectionTitle title='Training Overview' />
        <div className='content-top'>
          <p>
          Welcome to Gen Con’s Electronic Ticketing Training, an interactive walkthrough of the E-Ticketing System for Event Organizers and Game Masters.
          </p>
          <p>
          During this walkthrough you will learn about the most common scenarios you are likely to encounter while managing e-ticketed events. You'll get hands-on experience scanning sample attendee badges with your mobile device or computer with barcode scanner.
          </p>
          <NavButtons nextOnClick={() => setContentKey('web1.2')} />
        </div>
      </div>
    ),

    'web1.2': (
      <div className='content'>
        <SectionTitle title='Training Overview' />
        <div className='content-top'>
          <p>The training tasks you'll be going through are listed in the sidebar on the left, if your screen is large enough to show it. If you're viewing this on a small screen you can access the list by selecting the task-list button in the upper left corner.</p>
          <p>Once you've completed a task on the list it will be checked off and your overall progress shown on the bar at the top. You can select tasks from the list that you've already completed to back up and go through them again.</p>
          <NavButtons backOnClick={()=> setContentKey('web1.1')} nextOnClick={() => setContentKey('web1.3')} />
        </div>
      </div>
    ),

    'web1.3': (
      <div className='content'>
        <SectionTitle title='Training Overview' />
        <div className='content-top'>
          <p>
          You can go through this training on either a computer or a mobile device. You can switch at any time during the walkthrough and you can repeat certain tasks or the whole walkthrough using a different device. You can also stop at any time and start again later right where you left off.
          </p>
          <p>
          Once you've completed the training you'll also get access to the E-Ticketing Sandbox where you can play with all the functions, scanning the sample badges, without all the instructional pop-ups.
          </p>
          <NavButtons backOnClick={()=> setContentKey('web1.2')} nextOnClick={async () => { await completeTask('training-overview'); setContentKey('web2.1')}} />
        </div>
      </div>
    ),


    'web2.1': (
      <div className='content'>
        <SectionTitle title='Sample Badge Sheet' />
        <div className='content-top'>
          <p>
          To complete the scenarios in this walkthrough you'll need a printed sheet of the sample attendee badges to scan. If you don't have the sheet already, download and print it out now before continuing.
          </p>
          <div className='centered'>
            <a onClick={() => { window.open('/images/training/eticketing/v1/eticketing_badges_training_download.pdf', '_blank')}} style={{cursor: 'pointer', display: 'inline-block', textDecoration: 'none'}}>
              <div style={{maxHeight: '350px', maxWidth: '175px', border:'1px transparent', borderRadius: '10px', background: '#004a98'}}>
              <img src='/images/training/eticketing/v1/eticketing_badges_training_download_icon.png' ></img>
              <label style={{color: 'white', textDecoration: 'none'}}>Download Sample Badge Sheet</label>
            </div>
            </a>

          </div>
          <NavButtons
            nextButtonText="NEXT"
            nextOnClick={async () => { setContentKey('web2.2') }}
          />
        </div>
      </div>
    ),

    'web2.2': (
      <div className='content'>
        <SectionTitle title='Badge Scanning' />
        <div className='content-top'>
          <p>
          If you are going to use the Gen Con Mobile App to interface with the E-Ticketing System you can scan attendee badges with either your mobile device's camera or with an external barcode scanner. If you are going to use the Web interface you must have an external USB or Bluetooth barcode scanner to scan badges. Visit the <a href='/e-ticketing' target='_blank'>E-Ticketing Information</a> page for more information about external barcode scanners and troubleshooting them.
          </p>
          <NavButtons
            nextbuttonText="NEXT"
            nextOnClick={async () => { await completeTask('preparing-for-training'); setContentKey('web3.1') }}
            backButtonText='BACK'
            backOnClick={() => setContentKey('web2.1')}
          />
        </div>
      </div>
    ),

    'web3.1': (
      <div className='content'>
        {isMobileScreen ?
        (
          <div className='content'>
            <div className='content-top'>
              <h2 className='section-title'>Select Your Interface</h2>
              <p>
              To continue this walkthrough on this device please open the Gen Con Mobile App (make sure you have the latest version) and sign into your gencon.com account. You should see a prompt to continue this training, but if you don't, go to <b>Host &gt; Training</b> in the menu and you should be brought right to where you left off.
              </p>

              <div className='centered'>
              <a href='/mobile_app' target='_blank'>Get The Gen Con Mobile App</a>
              </div>

              <p>
              If you would like to switch to a computer with a larger screen, and external scanner, to continue this walkthrough, go to <b>https://www.gencon.com/e-ticketing/training</b> and sign into your account. You will be taken to this training walkthrough right where you left off. Be sure to connect your scanner.
              </p>
            </div>
          </div>
        ) :
          (
            <div className='content-top'>
              <h2 className='section-title'>Select Your Interface</h2>
              <p>If you want to continue this walkthrough on this device make sure your external scanner is connected now.</p>
              <p>If you would like to continue the walkthrough using the Gen Con Mobile App, open it now (make sure you have the latest version) and sign into your gencon.com account. You should see a prompt to continue this training, but if you don't, go to <b>Host &gt; Training</b> in the menu and you should be brought right to where you left off.</p>
              <div className='centered'>
              <a href='/mobile_app' target='_blank'>Get The Gen Con Mobile App</a>
              </div>
              <NavButtons
                nextButtonText='NEXT'
                nextOnClick={async () => { await completeTask('select-interface'); setContentKey('task3.1') }}
              />
            </div>
          )
        }
      </div>
    ),

    'web3.2': (
      <ScreenCaptureBannerView
        title='Using the Mobile App'
        content='Log on to the Gen Con Mobile App. Open the Menu in the right corner, and click on the Host button to view the various tools.'
        onClick={() => setContentKey('web3.6')}
        marginTop='65%'
        buttonText={'OK'}
        isMobileScreen={isMobileScreen}
        mobileImage='/images/training/eticketing/v1/Web3.4(MobileHamburger menu).png'
        desktopImage='/images/training/eticketing/v1/Web3.4(MobileHamburger menu).png'
      />
    ),

    'web3.6': (
      <ScreenCaptureBannerView
        title='What Kind of Training Do You Need?'
        content='Click on the Training button to start E-ticketing Training.'
        onClick={() => setContentKey('web3.7')}
        buttonText={'NEXT'}
        altButtonText={'BACK'}
        altOnClick={() => setContentKey('web3.2')}
        marginTop='75%'
        isMobileScreen={isMobileScreen}
        mobileImage='/images/training/eticketing/v1/Web3.6-HostSubMenu.jpg'
        desktopImage='/images/training/eticketing/v1/Web3.6-HostSubMenu.jpg'
      />
    ),

    'web3.7': (
      isMobileScreen ?
        (
          <ScreenCaptureBannerView
            title='What Kind of Training Do You Need?'
            content='Click on the link to open or install the mobile app, then continue training on the mobile app.'
            onClick={() => window.location.href = '/mobile_app'}
            buttonText={'Download App'}
            altButtonText={'Main Menu'}
            altOnClick={() => setChecklistOpen(true)}
            isMobileScreen={isMobileScreen}
            mobileImage='/images/training/eticketing/v1/Web3.6-HostSubMenu.jpg'
            desktopImage='/images/training/eticketing/v1/Web3.6-HostSubMenu.jpg'
          />
        ) :
        (
          <ScreenCaptureBannerView
            title='What Kind of Training Do You Need?'
            content='Use the QR code on your mobile device to install the mobile app, or run the app and continue training on the mobile app.'
            onClick={async () => { await completeTask('select-interface'); window.location.href = '/mobile_app' }}
            buttonText={'Download Mobile App'}
            altButtonText={'Return to Main Menu'}
            altOnClick={() => setChecklistOpen(true)}
            isMobileScreen={isMobileScreen}
            mobileImage='/images/training/eticketing/v1/Web3.6-HostSubMenu.jpg'
            desktopImage='/images/training/eticketing/v1/Web3.6-HostSubMenu.jpg'
            raw={<div className='centered'>
              <p>Use the QR code on your mobile device to install the mobile app, or run the app and continue training on the mobile app.</p>
              <img src='/images/training/eticketing/v1/install-mobile-app-qr.png' />
            </div>
            }
          />
        )
    ),

    'task1.1': (
      <ScreenCaptureBannerView
        title='Review My Schedule'
        content='To Redeem E-tickets you need to view the event for which you wish to redeem E-tickets.'
        onClick={() => setContentKey('task1.2')}
        buttonText={'NEXT'}
        isMobileScreen={isMobileScreen}
        mobileImage='/images/training/eticketing/v1/Web-1.1-WebOnMobile.jpg'
        desktopImage='/images/training/eticketing/v1/Web-1.1-NoSidebar-updated.jpg'
      />
    ),

    'task1.2': (
      <ScreenCaptureTooltipView
        title='Review My Schedule'
        content='Go to "My Schedule" from the Profile Button'
        position={{ right: '27%', top: '19.6%' }}
        positionMobile={{ right: '32%', top: '21%' }}
        isMobileScreen={isMobileScreen}
        direction='left'
        onClick={() => setContentKey('task1.3')}
        altOnClick={() => setContentKey('task1.1')}
        altButtonText={'BACK'}
        mobileImage='/images/training/eticketing/v1/Web-1.2-WebOnMobile.jpg'
        desktopImage='/images/training/eticketing/v1/Web-1.2-NoSidebar-updated.jpg'
        buttonContent='NEXT'
      />
    ),

    // 'task1.3': (
    //   <ScreenCaptureTooltipView
    //     title='Review My Schedule'
    //     content='Then click on the My Schedule Link to go to “My Schedule”'
    //     position={{ right: '20%', top: '15.5%' }}
    //     positionMobile={{ right: '32%', top: '21%' }}
    //     arrowOffset='20%'
    //     isMobileScreen={isMobileScreen}
    //     direction='left'
    //     onClick={() => setContentKey('task1.4')}
    //     mobileImage='/images/training/eticketing/v1/Web-1.3-WebOnMobile.jpg'
    //     desktopImage='/images/training/eticketing/v1/Web-1.3-NoSidebar.jpg'
    //     buttonContent='NEXT'
    //   />
    // ),

    'task1.3': (
      <ScreenCaptureTooltipView
        title='Review My Schedule'
        content='These are your events. Events which you are the EO/GM are indicated with an EO/GM icon.'
        arrowOffset='90%'
        position={{ left: '10%', bottom: '30%' }}
        positionMobile={{ left: '13%', bottom: '15%' }}
        isMobileScreen={isMobileScreen}
        direction='right'
        onClick={() => setContentKey('task1.4')}
        altButtonText={'BACK'}
        altOnClick={() => setContentKey('task1.2')}
        mobileImage='/images/training/eticketing/v1/Web-1.4-WebOnMobile.jpg'
        desktopImage='/images/training/eticketing/v1/Web-1.4-NoSidebar.jpg'
        buttonContent='NEXT'
      />
    ),

    'task1.4': (
      <ScreenCaptureBannerView
        title='Review My Schedule'
        content='To Redeem E-tickets you need to view the event for which you wish to redeem E-tickets.'
        onClick={async () => { await completeTask('review-my-schedule'); setContentKey('task1.5') }}
        buttonText={'NEXT'}
        altButtonText={'BACK'}
        altOnClick={() => setContentKey('task1.3')}
        isMobileScreen={isMobileScreen}
        mobileImage='/images/training/eticketing/v1/Web-1.5-WebOnMobile.jpg'
        desktopImage='/images/training/eticketing/v1/Web-1.5-NoSidebar.jpg'
        marginTop='30%'
        raw={<div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex' }}>
            <i className='fa fa-ticket-e fa-2x' style={{ marginLeft: '20px' }}></i><p style={{ marginLeft: '10px' }}>E-ticketed events have a ticket icon with a lightning bolt.</p>
          </div>
          <div style={{ display: 'flex' }}>
            <i className='fa fa-ticket-horz fa-2x' style={{ marginLeft: '20px' }}></i><p style={{ marginLeft: '10px' }}>Paper ticketed events have a rectangle and NO lightning bolt in them.</p>
          </div>
        </div>}
      />
    ),

    'task1.5': (
      <ScreenCaptureBannerView
        title='Review My Schedule'
        raw = {
          <div>
            <p>You press/click on the Event to get the Details & Ticket information, as well as to access the Scan Tab so you can redeem E-tickets.</p>
            <p>Now let’s look at the Details screen.</p>
          </div>
        }
        marginTop='30%'
        onClick={() => setContentKey('task2.1')}
        altOnClick={() => { setContentKey('task1.4') }}
        isMobileScreen={isMobileScreen}
        mobileImage='/images/training/eticketing/v1/Web-1.7-WebOnMobile.jpg'
        desktopImage='/images/training/eticketing/v1/Web-1.7-NoSidebar.jpg'
        buttonText={'NEXT'}
        altButtonText={'BACK'}
      />
    ),

    // 'task1.8': (
    //   <div className='content'>
    //     <img src='/images/training/eticketing/v1/Web-1.8.jpg'></img>
    //     <Banner
    //       content={'Once you’ve pressed/clicked on the Event you’ll see the Details, Ticket & Scan tab.'}
    //       buttonText={'NEXT'}
    //       onClick={() => setContentKey('task1.9')}
    //     />
    //   </div>
    // ),
    // 'task1.9': (
    //   <ScreenCaptureBannerView
    //     title='Review My Schedule'
    //     content="Let's look at the Details Screen next!"
    //     onClick={() => { setContentKey('task2.1') }}
    //     buttonText={'NEXT'}
    //     isMobileScreen={isMobileScreen}
    //     mobileImage='/images/training/eticketing/v1/Web-1.9-WebOnMobile.jpg'
    //     desktopImage='/images/training/eticketing/v1/Web-1.9-NoSidebar.jpg'
    //   />
    // ),

    'task2.1': (
      <ScreenCaptureBannerView
        title='Review the E-Ticketing Details & Tickets Interface'
        content="Key event details for the event are found in the Details tab of the event."
        onClick={() => { setContentKey('task2.2') }}
        buttonText={'NEXT'}
        isMobileScreen={isMobileScreen}
        mobileImage='/images/training/eticketing/v1/Web-2.1-WebOnMobile.jpg'
        desktopImage='/images/training/eticketing/v1/Web-2.1-NoSidebar.jpg'
      />
    ),

    'task2.2': (
      <ScreenCaptureBannerView
        title='Review the E-Ticketing Details & Tickets Interface'
        onClick={() => { setContentKey('task2.3') }}
        buttonText={'NEXT'}
        altButtonText={'BACK'}
        altOnClick={() => { setContentKey('task2.1') }}
        isMobileScreen={isMobileScreen}
        mobileImage='/images/training/eticketing/v1/Web-2.2-WebOnMobile.jpg'
        desktopImage='/images/training/eticketing/v1/Web-2.2-NoSidebar.jpg'
        marginTop='70%'
        raw={<div>
          <p>The Game ID is listed here.</p>
          <p>EO’s and GM’s listed in the GM section can redeem, sell and comp event tickets for the event. Use the EO/GM dashboard to add GM’s to this event.</p>
          <p>If the event is E-Ticketed, the ticketing method will show as ‘Electronic’. Otherwise it will show as ‘Paper’.</p>
        </div>}
      />
    ),
    'task2.3': (
      <ScreenCaptureBannerView
        title='Using the E-Ticketing Scan Interface'
        content='Troubleshooting tip: If you encounter issues, make sure to verify Event Details such as event date/time, and whether the event is Electronic or Paper.'
        onClick={() => { setContentKey('task2.4') }}
        buttonText={'NEXT'}
        altButtonText={'BACK'}
        altOnClick={() => { setContentKey('task2.2') }}
        isMobileScreen={isMobileScreen}
        mobileImage='/images/training/eticketing/v1/Web-2.5-WebOnMobile.jpg'
        desktopImage='/images/training/eticketing/v1/Web-2.5-NoSidebar.jpg'
        raw={<p>
          <b>Troubleshooting Tip:</b> If you encounter issues, make sure to verify Event Details such as event date/time, and whether the event is Electronic or Paper.
        </p>}
      />
    ),

    'task2.4': (
      <ScreenCaptureBannerView
        title='Review the E-Ticketing Details & Tickets Interface'
        content="The Tickets tab displays tickets associated with this event."
        onClick={() => { setContentKey('task2.5') }}
        altButtonText={'BACK'}
        altOnClick={() => { setContentKey('task2.3') }}
        buttonText={'NEXT'}
        isMobileScreen={isMobileScreen}
        mobileImage='/images/training/eticketing/v1/Web-2.6-WebOnMobile.jpg'
        desktopImage='/images/training/eticketing/v1/Web-2.6-NoSidebar.jpg'
      />
    ),

    'task2.5': (
      <ScreenCaptureBannerView
        title='Review the E-Ticketing Details & Tickets Interface'
        onClick={() => setContentKey('task2.6')}
        buttonText={'NEXT'}
        altButtonText={'BACK'}
        altOnClick={() => { setContentKey('task2.4') }}
        isMobileScreen={isMobileScreen}
        mobileImage='/images/training/eticketing/v1/Web-2.7-WebOnMobile.jpg'
        desktopImage='/images/training/eticketing/v1/Web-2.7-NoSidebar.jpg'
        raw={<div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className='flex circled-icon-row'>
            <div className='flex'>
              <i className='fa fa-ticket-e flex-center circled-icon' style={{ color: 'black' }}></i>
              <i className='fa fa-ticket-horz flex-center circled-icon' style={{ color: 'black' }}></i>
            </div>
            <p>Black ticket icons with a lightning bolt are unredeemed e-tickets. Icons with NO lightning bolt are for paper tickets.</p>
          </div>
          <br />

          <div className='flex circled-icon-row' >
            <div className='flex'>
              <i className='fa e-ticket-success-color fa-ticket-checked flex-center circled-icon'></i>
            </div>
            <p>Green tickets are successfully redeemed.</p>
          </div>
          <br />

          <div className='flex circled-icon-row'>
            <div className='flex' style={{ alignItems: 'center' }}>
              <i className='fa e-ticket-pending-color fa-ticket-in-cart flex-center circled-icon'></i>
            </div>
            <p>Orange ticket-in-cart icons are tentatively sold to an attendee. They are awaiting approval by the purchaser. Once approved, the ticket is redeemed and the icon changes to a green redeemed-ticket.</p>
          </div>
          <br />
          <div className='flex circled-icon-row'>
            <div className='flex' style={{ position: 'relative', alignItems: 'center' }}>
              <span className="ticket-count-num" style={{ top: '11px', fontSize: '1em', left: '0' }}>+3</span>
              <i className='fa e-ticket-attention-color fa-ticket-horz-blank flex-center  circled-icon'></i>
            </div>
            <p>Ticket icons with numbers indicate that the attendee has more than one ticket for the event. The number is how many additional (guest) tickets they have. Yellow indicates the attendee's ticket has been redeemed but their guest tickets have not.</p>
          </div>
          <br />
        </div>}
      />
    ),

    'task2.6': (
      <ScreenCaptureBannerView
        title='Review the E-Ticketing Details & Tickets Interface'
        content="TROUBLESHOOTING TIP: Use the tickets screen to view which tickets have been sold and/or redeemed for this event, and to see total available tickets. Now we'll review the Scan tab."
        onClick={async () => { await completeTask('review-e-ticketing-details-interface'); setContentKey('task3.1') }}
        buttonText={'NEXT'}
        altButtonText={'BACK'}
        isMobileScreen={isMobileScreen}
        mobileImage='/images/training/eticketing/v1/Web-2.11-WebOnMobile.jpg'
        desktopImage='/images/training/eticketing/v1/Web-2.11-NoSidebar.jpg'
        altOnClick={() => { setContentKey('task2.5')}}
        raw={
          <div>
            <p><b>Troubleshooting Tip:</b> Use the tickets screen to view which tickets have been sold and/or redeemed for this event, and to see total available tickets. </p>
            <p>Now we'll review the Scan Interface.</p>
          </div>
        }
      />
    ),

    'task3.1': (
      <MarkupTooltip
        key='task3.1'
        markup={
          <div className='content'>
            <h2 className='section-title'>Using the E-Ticketing Scan Interface</h2>
            <ScanningScreen5Badges />
          </div>
        }
        placement="bottom"
        selector="#scans-anchor"
        raw={
          <div>
            <p>The Scan Interface is where you scan attendee badges in order to redeem their e-tickets. You get to the Scan Interface by selecting the <b>Scans</b> tab when viewing your event’s details. Each time a badge is scanned the result will appear at the top of the list.</p>
          </div>
        }
        onClick={() => { setContentKey('task3.2') }}
        buttonText={'NEXT'}
        title='Using the E-Ticketing Scan Interface'
      />
    ),

    'task3.2': (
        <MarkupTooltip
        key='task3.2'
        markup={
          <div className='content'>
            <h2 className='section-title'>Using the E-Ticketing Scan Interface</h2>
            <ScanningScreen5Badges />
          </div>
        }
        placement="bottom"
        selector="#scan-bar-anchor"
        content='The green bar with “Ready to scan a badge” indicates you are ready to begin scanning. If you accidentally click out of the scan screen, the text will say “Select to enable scanning” and you must click on the window to get back to “Ready to scan a badge”.'
        onClick={() => setContentKey('task3.3')}
        buttonText='NEXT'
        altButtonText={'BACK'}
        altOnClick={() => { setContentKey('task3.1') }}
      />
    ),

    'task3.3': (
      <MarkupBanner
      key='task3.3'
      markup={
        <div className='content'>
          <h2 className='section-title'>Using the E-Ticketing Scan Interface</h2>
          <ScanningScreen5Badges />
        </div>
      }
      selector="#icon-explain-anchor"
      raw={
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className='flex circled-icon-row'>
            <div className='flex'>
              <i className='fa e-ticket-success-color fa-ticket-checked circled-icon'></i>
            </div>
            <p>Green ticket icons with a check mark are redeemed e-tickets.</p>
          </div>
          <div className='flex circled-icon-row'>
            <div className='flex'>
              <span className="ticket-count-container" style={{width: '45px'}}>
                <div className="ticket-count-num" style={{ paddingTop: '32px', fontSize: '0.8em', textAlign: 'center'}}>+3</div>
                <i className='fa e-ticket-attention-color fa-ticket-horz-blank circled-icon'></i></span>
            </div>
            <p>Yellow ticket icons with a number are redeemed e-tickets for an attendee that still has unredeemed guest tickets.</p>
          </div>

          <div className='flex circled-icon-row'>
            <div className='flex'>
              <i className='fa e-ticket-attention-color fa-no-ticket circled-icon'></i>
            </div>
            <p>Yellow ticket icons with a question mark mean the attendee doesn’t have any tickets for this event. You can choose to sell or comp tickets for paid events, or issue tickets for free events.</p>
          </div>

          <div className='flex circled-icon-row'>
            <div className='flex'>
              <i className='fa e-ticket-pending-color fa-ticket-in-cart circled-icon'></i>
            </div>
            <p>Orange ticket-in-cart icons mean the attendee must approve purchase of a ticket. The icon turns green when the purchase is approved.</p>
          </div>

          <div className='flex circled-icon-row'>
            <div className='flex'>
              <i className='fa e-ticket-error-color fa-badge-error circled-icon'></i>
            </div>
            <p>Red badge-problem icons indicate that an ineligible or invalid badge has been scanned.</p>
          </div>
        </div>
      }
      onClick={() => setContentKey('task3.4')}
      buttonText='NEXT'
      altButtonText={'BACK'}
      altOnClick={() => { setContentKey('task3.2') }}
      marginTop='40%'
      />
    ),

    'task3.4': (
      <MarkupTooltip
        key='task3.4'
        selector={'#scan-filter-anchor'}
        markup={
          <div className='content'>
            <h2 className='section-title'>Using the E-Ticketing Scan Interface</h2>
            <ScanningScreen5Badges />
          </div>
        }
        title='Using the E-Ticketing Scan Interface'
        content={'This Filter Bar shows the number of scans by status. You can select a status to filter the list.'}
        direction='bottom'
        onClick={() => setContentKey('task3.5')}
        buttonText='NEXT'
        altButtonText={'BACK'}
        altOnClick={() => { setContentKey('task3.3') }}
      />
    ),

    'task3.5': (
      <MarkupBanner
        key='task3.5'
        markup={
          <div className='content'>
            <h2 className='section-title'>Using the E-Ticketing Scan Interface</h2>
            <ScanningScreen5Badges />
          </div>
        }
        selector="#scan-filter-anchor"
        direction='bottom'
        onClick={() => setContentKey('task3.6')}
        buttonText='NEXT'
        altButtonText={'BACK'}
        altOnClick={() => { setContentKey('task3.4') }}
        marginTop='23em'
        raw={
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className='flex circled-icon-row'>
              <div className='flex'>
                <i className='fa e-ticket-success-color fa fa-check fa-2x circled-icon'></i>
              </div>
              <p>Green filter displays all successfully redeemed e-tickets.</p>
            </div>
            <div className='flex circled-icon-row'>
              <div className='flex'>
                <i className='fa e-ticket-pending-color fa-refresh fa-2x circled-icon' style={{paddingLeft: '4px'}}></i>
              </div>
              <p>Orange filter displays e-tickets awaiting processing or purchase approval.</p>
            </div>
            <div className='flex circled-icon-row'>
              <div className='flex'>
                <i className='fa e-ticket-attention-color fa fa-alert fa-2x circled-icon'></i>
              </div>
              <p>Yellow filter displays scans that need attention like unredeemed guest tickets or attendees without tickets.</p>
            </div>
            <div className='flex circled-icon-row'>
              <div className='flex'>
                <i className='fa e-ticket-error-color fa fa-exclamation fa-2x circled-icon'></i>
              </div>
              <p>Red filter displays ineligible or invalid badges which have been scanned.</p>
            </div>
          </div>
        }
      />
    ),

    'task3.6': (
      <MarkupBanner
        key='task3.6'
        markup={
          <div className='content'>
            <h2 className='section-title'>Using the E-Ticketing Scan Interface</h2>
            <ScanningScreen5Badges />
          </div>
        }
        selector="#scan-filter-anchor"
        buttonText={'My scanner is connected. I’m ready!'}
        altButtonText={'BACK'}
        onClick={async () => { await completeTask('using-scan-interface'); setContentKey('task4.1') }}
        altOnClick={() => { setContentKey('task3.5')}}
        raw={
          <div>
            <p>Now that you understand the Scan Interface, let’s redeem some e-tickets!</p>
            <p>For the Web interface, you must have a Bluetooth or USB barcode scanner to scan badges. Refer to your scanner's manual if you need help connecting or configuring it. You must have it connected now in order to complete the upcoming scenarios.</p>
          </div>
        }
      />
    ),

    'task4.1': (
      <div className='content'>
        <h2 className='section-title'>Redeeming E-Tickets </h2>
        <DemoTicketRedemptions
          contentKey={contentKey}
          setContentKey={setContentKey}
          completeTask={completeTask}
          scans={scans}
          setScans={setScans}
          scanningDisabled={false}
        />
        <Banner
          raw={
            <div>
              <p>For the next few scenarios you'll be scanning the sample badges that you printed out.</p>
              <p>Let's scan the badge of an attendee with one e-ticket for this event. Scan the badge of <b>"Fred One-Tic"</b>.</p>
            </div>
          
          }
          icon={'fa fa-scanner fa-2x'}
        />
      </div>
    ),

    'task4.1-error': (
      <div className='content'>
        <h2 className='section-title'>Redeeming E-Tickets </h2>
        <Modal
          title={'Wrong Badge Scanned'}
          content={<div><div className='banner-button-group'><i className='fa fa-scanner fa-2x'></i></div><p>Incorrect Badge. Please Scan <b>Fred One-Tic</b>.</p></div>}
        />
        <DemoTicketRedemptions
          contentKey={contentKey}
          setContentKey={setContentKey}
          completeTask={completeTask}
          scans={scans}
          setScans={setScans}
          scanningDisabled={false}
        />
      </div>
    ),

    'task4.2': (
      <MarkupTooltip
        key='task4.2'
        markup={
          <div className='content'>
            <h2 className='section-title'>Redeeming E-Tickets</h2>
           <DemoTicketRedemptions contentKey={contentKey} setContentKey={setContentKey} completeTask={completeTask} scans={scans} setScans={setScans} />
          </div>
        }
        selector="#scan-icon-0"
        title='Redeeming E-Tickets'
        content={"The green ticket icon shows that you've redeemed the attendee's e-ticket, and the count of redeemed tickets on the filter bar has incremented."}
        onClick={ async () => { await completeTask('redeeming-tickets'); setContentKey('task4.3') }}
        buttonText={'NEXT'}
        altButtonText={'BACK'}
        altOnClick={() => { setScans([...scans.slice(1)]); setContentKey('task4.1') }}
      />
    ),

    'task4.3': (
      <div className='content'>
        <h2 className='section-title'>Redeeming E-Tickets</h2>
        <DemoTicketRedemptions
          contentKey={contentKey}
          setContentKey={setContentKey}
          completeTask={completeTask}
          scans={scans}
          setScans={setScans}
        />
        <Banner
          content={"Congratulations! You've redeemed an e-ticket. Let's try another scenario."}
          buttonText={'NEXT SCENARIO'}
          onClick={() => { setContentKey('task5.1') }}
          altButtonText={'BACK'}
          altOnClick={() => {setContentKey('task4.2') }}
        />
      </div>
    ),

    'task5.1': (
      <div className='content'>
        <h2 className='section-title'>Redeeming Guest E-Tickets</h2>
        <DemoTicketRedemptions
          contentKey={contentKey}
          setContentKey={setContentKey}
          completeTask={completeTask}
          scans={scans}
          setScans={setScans}
          scanningDisabled={false}
        />
        <Banner
          content={'Handling an attendee with Guest Tickets requires special steps to redeem Guest Tickets. To scan a valid badge with multiple E-tickets for this event, you first scan the Host’s badge. Scan our host, “Allie Three-Tix”.'}
          icon={'fa fa-scanner fa-2x'}
          raw={<div>
            <p>Most events allow attendees to get more than one ticket so they can give the extra tickets to their friends / guests.</p>
            <p><b>"Allie Three-Tix"</b> has three tickets for this event. Scan her badge now.</p>
          </div>}
        />
      </div>
    ),

    'task5.1-error': (
      <div className='content'>
        <h2 className='section-title'>Redeeming Guest E-Tickets</h2>
        <Modal
          title={'Wrong Badge Scanned'}
          content={<div><div className='banner-button-group'><i className='fa fa-scanner fa-2x'></i></div><p>Incorrect Badge. Please Scan <b>Allie Three-Tix</b>.</p></div>}
        />
        <DemoTicketRedemptions
          contentKey={contentKey}
          setContentKey={setContentKey}
          completeTask={completeTask}
          scans={scans}
          setScans={setScans}
          scanningDisabled={false}
        />
      </div>
    ),

    'task5.2': (
      <MarkupTooltip
        key='task5.2'
        markup={
          <div className='content'>
            <h2 className='section-title'>Redeeming Guest E-Tickets</h2>
           <DemoTicketRedemptions contentKey={contentKey} setContentKey={setContentKey} completeTask={completeTask} scans={scans} setScans={setScans} />
          </div>
        }
        selector="#scan-icon-0"
        title='Redeeming E-Tickets'
        content={"Allie's ticket has been redeemed but the yellow ticket icon, with the number in it, indicates that she still has unredeemed tickets for two guests."}
        onClick={() => { setContentKey('task5.3') }}
        buttonText={'NEXT'}
        altButtonText={'BACK'}
        altOnClick={() => { setScans([...scans.slice(1)]); setContentKey('task5.1') }}
      />
    ),

    'task5.3': (
      <div className='content'>
        <h2 className='section-title'>Redeeming Guest E-Tickets</h2>
        <DemoTicketRedemptions
          key={contentKey}
          contentKey={contentKey}
          setContentKey={setContentKey}
          completeTask={completeTask}
          scans={scans}
          setScans={setScans}
        />
        <Banner
          content={"If one of Allie's guests is with her, they have an eligible badge, and they don't have a ticket for this event, you can transfer one of Allie's guest tickets to them."}
          buttonText={'NEXT'}
          onClick={() => { setContentKey('task5.4') }}
          altButtonText={'BACK'}
          altOnClick={() => { setContentKey('task5.2') }}
        />
      </div>
    ),

    'task5.4': (
      <MarkupTooltip
        key={contentKey}
        markup={
          <div className='content'>
            <h2 className='section-title'>Redeeming Guest E-Tickets</h2>
          <DemoTicketRedemptions contentKey={contentKey} setContentKey={setContentKey} completeTask={completeTask} scans={scans} setScans={setScans} />
          </div>
        }
        selector="#scan-row-0"
        title='Redeeming E-Tickets'
        raw={
          <div style={{fontWeight: 'normal'}}>
            <p>To transfer and redeem a guest e-ticket:</p>
            <ul>
              <li>Select the name of the attendee with guest tickets (Allie Three-Tix)</li>
              <li>Select the "Scan Guest Badges" button</li>
            </ul>
          </div>
        }
        disable={false}
      />
    ),

    'task5.4b' : (
      <MarkupTooltip
        key={contentKey}
        markup={
          <div className='content'>
            <h2 className='section-title'>Redeeming Guest E-Tickets</h2>
          <DemoTicketRedemptions 
          key={contentKey}
          contentKey={contentKey} setContentKey={setContentKey} completeTask={completeTask} scans={scans} setScans={setScans} 
          initialGuestScanning={{enabled: true, host: { id: 3, badge_code: 'B-9ER5QZ771RRN', state: 'partially_redeemed', message: 'Allie has guest tickets remaining - select to scan', ticketholder: { first_name: 'Allie', last_name: 'Three-Tix' }, host_ticketholder: { first_name: 'Allie', last_name: 'Three-Tix' }, event_choices: [], additional_tickets: 2, updated_at: '2020-01-01T00:00:00.000Z' }}}
          scanningDisabled={false}
          initialOptionsOpenID={3}
          />
          </div>
        }
        title='Redeeming Guest E-Tickets'
        selector="#scan-row-0"
        direction={'top'}
        contentKey={contentKey}
        setContentKey={setContentKey}
        completeTask={completeTask}
        scans={scans}
        setScans={setScans}
        disable={false}
        raw={
          <div>
            <p>Now you’re in Guest Scanning Mode. Scan the badge of <b>Julie Guest</b> to transfer and redeem a guest ticket for her.</p>
            <div className='centered'>
              <i className='fa fa-scanner fa-2x'></i>
            </div>
          </div>
        }
      />
    ),

    'task5.4-error': (
      <div className='content'>
        <h2 className='section-title'>Redeeming Guest E-Tickets</h2>
        <Modal
          title={'Wrong Badge Scanned'}
          content={<div><div className='banner-button-group'><i className='fa fa-scanner fa-2x'></i></div><p>Incorrect Badge. Please Scan <b>Julie Guest</b>.</p></div>}
        />
        <DemoTicketRedemptions
          key={contentKey}
          contentKey={contentKey}
          setContentKey={setContentKey}
          completeTask={completeTask}
          scans={scans}
          setScans={setScans}
          scanningDisabled={false}
        />
      </div>
    ),

    'task5.4-error2': (
      <div className='content'>
        <h2 className='section-title'>Redeeming Guest E-Tickets</h2>
        <Modal
          title={'Not in Guest Scanning Mode'}
          content={<div><div className='banner-button-group'><i className='fa fa-scanner fa-2x'></i></div><p>You should be in Guest Scanning mode. Please enter Guest Scanning mode and then scan <b>Julie Guest</b>.</p></div>}
          buttonText={'OK'}
          onClick={() => { setContentKey('task5.4') }}
        />
        <DemoTicketRedemptions
          contentKey={contentKey}
          setContentKey={setContentKey}
          completeTask={completeTask}
          scans={scans}
          setScans={setScans}
          scanningDisabled={false}
        />
      </div>
    ),

    'task5.5': (
      <div className='content'>
        <h2 className='section-title'>Redeeming Guest E-Tickets</h2>
        <DemoTicketRedemptions
          key={contentKey}
          contentKey={contentKey}
          setContentKey={setContentKey}
          completeTask={completeTask}
          initialGuestScanning={{enabled: true, host: { id: 3, badge_code: 'B-9ER5QZ771RRN', state: 'partially_redeemed', message: 'Allie has guest tickets remaining - select to scan', ticketholder: { first_name: 'Allie', last_name: 'Three-Tix' }, host_ticketholder: { first_name: 'Allie', last_name: 'Three-Tix' }, event_choices: [], additional_tickets: 2, updated_at: '2020-01-01T00:00:00.000Z' }}}
          scans={scans}
          setScans={setScans}
        />
        <Banner
          raw={
            <div>
              <p>Great! You've transferred and redeemed a guest e-ticket.</p>
              <p>When you transfer an attendee's last guest ticket the interface will automatically exit guest scanning mode and the attendee's ticket will turn green. If the attendee has more guest tickets, but their guests are not present yet, you can exit guest scanning mode in order to scan other attendees' badges.</p>
              <p>Select <b>"Stop Scanning Guest Badges"</b> now.</p>
            </div>
          }
        />
      </div>
    ),

    'task5.6': (
      <div className='content'>
        <h2 className='section-title'>Redeeming Guest E-Tickets</h2>
        <DemoTicketRedemptions
          key={'task5.6'}
          contentKey={contentKey}
          setContentKey={setContentKey}
          completeTask={completeTask}
          scans={scans}
          setScans={setScans}
          scanningDisabled={true}
        />
        <Banner
          content={'Excellent! Now, let’s continue with the next scenario.'}
          buttonText={'Next Scenario'}
          onClick={() => { setContentKey('task6.1') }}
          altButtonText={'BACK'}
          altOnClick={() => {setContentKey('task5.5') }}
        />
      </div>
    ),

    'task6.1': (
      <div className='content'>
      <h2 className='section-title'>Selling and Comping E-Tickets</h2>
      <DemoTicketRedemptions
        contentKey={contentKey}
        setContentKey={setContentKey}
        completeTask={completeTask}
        scans={scans}
        setScans={setScans}
        scanningDisabled={false}
      />
      <Banner
        raw={
          <div>
            <p>Event hosts may sell or comp electronic tickets for their event directly to attendees.</p>
            <p>Let's sell an e-ticket by scanning the badge of an attendee who doesn't have a ticket to this event.</p>
            <p>Scan <b>"Casper No-Tix".</b></p>
          </div>
        }
        icon={'fa fa-scanner fa-2x'}
      />
    </div>
    ),

    'task6.1-error': (
      <div className='content'>
        <h2 className='section-title'>Selling and Comping E-Tickets</h2>
        <Modal
          title={'Wrong Badge Scanned'}
          content={<div><div className='banner-button-group'><i className='fa fa-scanner fa-2x'></i></div><p>Incorrect Badge. Please Scan <b>Casper No-Tix</b>.</p></div>}
        />
        <DemoTicketRedemptions
          contentKey={contentKey}
          setContentKey={setContentKey}
          completeTask={completeTask}
          scans={scans}
          setScans={setScans}
          scanningDisabled={false}
        />
      </div>
    ),

    'task6.2': (
      <MarkupTooltip
        markup={
          <div className='content'>
            <h2 className='section-title'>Selling and Comping E-Tickets</h2>
          <DemoTicketRedemptions contentKey={contentKey} setContentKey={setContentKey} completeTask={completeTask} scans={scans} setScans={setScans} />
          </div>
        }
        title='Selling and Comping E-Tickets'
        content={'Notice that the badge is flagged with a no ticket icon, and it says, “no ticket found”. You tap the user’s name to see options.'}
        selector={'#scan-icon-0'}
        direction={'top'}
        contentKey={contentKey}
        setContentKey={setContentKey}
        completeTask={completeTask}
        scans={scans}
        setScans={setScans}
        disable={false}
        raw={
          <div className='flex circled-icon-row'>
            <div className='flex'>
              <i className='fa e-ticket-attention-color fa-no-ticket fa-4x'></i>
            </div>
            <p style={{fontWeight: 'normal'}}>The yellow no-ticket icon indicates the attendee doesn't have a ticket for this event. Select their name to see options for getting them a ticket.</p>
        </div>
        }
      />
    ),

    'task6.3': (
      <div className='content'>
        <h2 className='section-title'>Selling and Comping E-Tickets</h2>
        <DemoTicketRedemptions
          contentKey={contentKey}
          setContentKey={setContentKey}
          completeTask={completeTask}
          scans={scans}
          setScans={setScans}
          scanningDisabled={true}
        />
        <Banner
          raw={
            <div>
              <p>If this were a free event you would see an option to issue a ticket to the attendee. Issued tickets are immediately redeemed.</p>
              <p>If the event is not free you may have options to either sell or comp a ticket to the attendee. Selling a ticket requires purchaser approval. Tickets are not redeemed until the purchaser approves.</p>
            </div>
          }
          buttonText={'NEXT'}
          onClick={() => { setContentKey('task6.4') }}
          altButtonText={'BACK'}
          altOnClick={() => {setContentKey('task6.2') }}
        />
      </div>
    ),

    'task6.4': (
      <div className='content'>
        <h2 className='section-title'>Selling and Comping E-Tickets</h2>
        <DemoTicketRedemptions
          contentKey={contentKey}
          setContentKey={setContentKey}
          completeTask={completeTask}
          scans={scans}
          setScans={setScans}
          scanningDisabled={true}
        />
        <Banner
          raw={
            <div>
              <p>Sometimes the attendee is not able to approve the purchase of an e-ticket sold by an event host, typically because they don't have a gencon.com user account. If the attendee's badge was purchased for them by a friend or family member, the system will offer to have them purchase the ticket.</p>
              <p>Occasionally there may be no way to sell a ticket to an attendee. In this case you may either comp them a ticket or ask them to go to the nearest Event Reg or Customer Service kiosk to purchase a ticket.</p>
            </div>
          }
          buttonText={'NEXT'}
          onClick={() => { setContentKey('task6.5') }}
          altButtonText={'BACK'}
          altOnClick={() => {setContentKey('task6.3') }}
        />
      </div>
    ),

    'task6.5': (
      <MarkupTooltip
        key='task6.5'
        markup={
          <div className='content'>
            <h2 className='section-title'>Selling and Comping E-Tickets</h2>
          <DemoTicketRedemptions contentKey={contentKey} setContentKey={setContentKey} completeTask={completeTask} scans={scans} setScans={setScans} />
          </div>
        }
        title='Selling and Comping E-Tickets'
        content={'Notice that the badge is flagged with a no ticket icon, and it says, “no ticket found”. You tap the user’s name to see options.'}
        position={{ right: '50%', top: '16rem' }}
        placement={'bottom'}
        contentKey={contentKey}
        setContentKey={setContentKey}
        completeTask={completeTask}
        scans={scans}
        selector={'#sell-ticket-button-0'}
        setScans={setScans}
        disable={false}
        raw={
          <div style={{fontWeight: 'normal'}}>
            <p>Always check the message to see if someone other than the attendee must approve the purchase. For this event <b>Natalie Money</b> must approve the purchase <b><i><u>instead</u></i></b> of <b>Casper</b>.</p>
            <p>It's best to check with attendees before selling them a ticket that must be approved by someone else so they can communicate with their friend to make sure the purchase gets approved.</p>
            <p>Select the <b>SELL TICKET</b> button now.</p>
          </div>
      }
    />
    ),
    
    'task6.5-error': (
      <div className='content'>
        <h2 className='section-title'>Selling and Comping E-Tickets</h2>
        <Modal
          title={'Wrong Button Pressed'}
          content={<div><p>For this training, you should sell a ticket. Please press the <b>Sell Ticket</b> button.</p></div>}
        />
        <DemoTicketRedemptions
          contentKey={contentKey}
          setContentKey={setContentKey}
          completeTask={completeTask}
          scans={scans}
          setScans={setScans}
          scanningDisabled={false}
        />
      </div>
    ),

    'task6.6': (
      <MarkupTooltip
        key='task6.6'
        markup={
          <div className='content'>
            <h2 className='section-title'>Selling and Comping E-Tickets</h2>
          <DemoTicketRedemptions contentKey={contentKey} setContentKey={setContentKey} completeTask={completeTask} scans={scans} setScans={setScans} />
          </div>
        }
        raw={
          <div style={{fontWeight: 'normal'}}>
            <p>The no-ticket icon changes to an orange ticket-in-cart icon to indicate that it's awaiting purchaser approval.</p>
            <p>The purchaser will get an email with a link to approve the purchase or they can go directly to their cart page on gencon.com where they can see and approve the e-ticket purchase at the top of the page.</p>
            <p>Approve the purchase to see what happens next.</p>
          </div>
        }
        onClick={() => { setContentKey('task6.7'); setScans(scans.map(scan => { if (scan.state === 'pending_sale') { scan.state = 'redeemed'; scan.message = '' } return scan; })) }}
        selector={'#scan-icon-0'}
        buttonText={'APPROVE PURCHASE'}
        direction={'bottom'}
        contentKey={contentKey}
        setContentKey={setContentKey}
        completeTask={completeTask}
        scans={scans}
        setScans={setScans}
      />
    ),

    'task6.7': (
      <div className='content'>
        <h2 className='section-title'>Selling and Comping E-Tickets</h2>
        <DemoTicketRedemptions
          contentKey={contentKey}
          setContentKey={setContentKey}
          completeTask={completeTask}
          scans={scans}
          setScans={setScans}
        />
        <Banner
          content={'When an e-ticket purchase is approved the ticket is redeemed and the ticket-in-cart icon changes to the green redeemed-ticket icon.'}
          buttonText={'NEXT'}
          onClick={() => { setContentKey('task6.8') }}
        />
      </div>
    ),

    'task6.8': (
      <div className='content'>
        <h2 className='section-title'>Selling and Comping E-Tickets</h2>
        <DemoTicketRedemptions
          contentKey={contentKey}
          setContentKey={setContentKey}
          completeTask={completeTask}
          scans={scans}
          setScans={setScans}
        />
        <Banner
          content={"If your event is sold out you can still sell or comp e-tickets to fill spots of attendees that didn't show up. The system will ask you to confirm that you want to over sell your event."}
          buttonText={'NEXT'}
          onClick={async () => { await completeTask('sell-comp-ticket'); setContentKey('task6.9') }}
          altButtonText={'BACK'}
          altOnClick={() => {setContentKey('task6.7') }}
        />
      </div>
    ),

    'task6.9': (
      <div className='content'>
        <h2 className='section-title'>Selling and Comping E-Tickets</h2>
        <DemoTicketRedemptions
          contentKey={contentKey}
          setContentKey={setContentKey}
          completeTask={completeTask}
          scans={scans}
          setScans={setScans}
        />
        <Banner
          content={"Another scenario completed! Now you know all about selling and comping e-tickets for your event. If you have any questions or issues during Gen Con please go to the nearest Event HQ for assistance."}
          buttonText={'Next Scenario'}
          onClick={() => { setContentKey('task7.1') }}
          altButtonText={'BACK'}
          altOnClick={() => {setContentKey('task6.8') }}
        />
      </div>
    ),

    'task7.1': (
      <div className='content'>
        <h2 className='section-title'>Dealing with Problems</h2>
        <DemoTicketRedemptions
          contentKey={contentKey}
          setContentKey={setContentKey}
          completeTask={completeTask}
          scans={scans}
          setScans={setScans}
        />
        <Banner
          raw={
            <div>
              <p>Sometimes you will encounter issues while redeeming e-tickets. The E-Ticketing System will inform you if you scan a badge that is invalid or ineligible for your event by displaying a red badge-problem icon along with a short message describing the problem.</p>
              <div className='banner-button-group'>
              <i className='fa e-ticket-error-color fa-badge-error fa-2x circled-icon'></i>
              </div>
              <br/>
            </div>
            }
          buttonText={'NEXT'}
          onClick={() => { setContentKey('task7.2') }}
        />
      </div>
    ),

    'task7.2': (
      <div className='content'>
        <h2 className='section-title'>Dealing with Problems</h2>
        <DemoTicketRedemptions
          contentKey={contentKey}
          setContentKey={setContentKey}
          completeTask={completeTask}
          scans={scans}
          setScans={setScans}
          scanningDisabled={false}
        />
        <Banner
          raw={
            <div>
              <p>Let’s scan a badge that does not have access to this event.</p>
              <p>Scan <b>“Clyde Bad-Badge”</b>.</p>
            </div>
          
          }
          icon={'fa fa-scanner fa-2x'}
        />
      </div>
    ),

    'task7.2-error': (
      <div className='content'>
        <h2 className='section-title'>Dealing with Problems</h2>
        <Modal
          title={'Wrong Badge Scanned'}
          content={<div><div className='banner-button-group'><i className='fa fa-scanner fa-2x'></i></div><p>Incorrect Badge. Please Scan <b>Clyde Bad-Badge</b>.</p></div>}
        />
        <DemoTicketRedemptions
          contentKey={contentKey}
          setContentKey={setContentKey}
          completeTask={completeTask}
          scans={scans}
          setScans={setScans}
          scanningDisabled={false}
        />
      </div>
    ),

    'task7.3': (
      <MarkupTooltip
        title='Dealing with Problems'
        key='task7.3'
        markup={
          <div className='content'>
            <h2 className='section-title'>Dealing with Problems</h2>
          <DemoTicketRedemptions contentKey={contentKey} setContentKey={setContentKey} completeTask={completeTask} scans={scans} setScans={setScans} />
          </div>
        }
        content={'If an attendee does not have a badge for the day of the event, or does not have a badge that allows access to the event, like Trade Day or VIG events, you will see the message, “Attendee does not have a badge with access to this event” along with the red badge-problem icon.'}
        placement={'left'}
        contentKey={contentKey}
        setContentKey={setContentKey}
        completeTask={completeTask}
        scans={scans}
        setScans={setScans}
        selector='#scan-icon-0'
        buttonText={'NEXT'}
        onClick={() => { setContentKey('task7.4') }}
        altButtonText={'BACK'}
        altOnClick={() => { setScans([...scans.slice(1)]); setContentKey('task7.2') }}
    />
    ),

    'task7.4': (
      <MarkupTooltip
      title='Dealing with Problems'
      key='task7.4'
      markup={
        <div className='content'>
          <h2 className='section-title'>Dealing with Problems</h2>
        <DemoTicketRedemptions contentKey={contentKey} setContentKey={setContentKey} completeTask={completeTask} scans={scans} setScans={setScans} />
        </div>
      }
      raw={
        <div style={{fontWeight: 'normal'}}>
          <p>
          Occasionally you may receive the “Attendee badge not found” message, indicating that the badge you’ve scanned is invalid. This can occur if the badge is for a previous convention or has been reported as lost. If this happens, please direct the attendee to Customer Service to get the issue resolved.
          </p>
        </div>
      }
      placement={'bottom'}
      selector={'#ticketholder-name-0'}
      contentKey={contentKey}
      setContentKey={setContentKey}
      completeTask={completeTask}
      scans={scans}
      setScans={setScans}
      buttonText={'NEXT'}
      onClick={() => { setContentKey('task7.5') }}
      altButtonText={'BACK'}
      altOnClick={() => { setContentKey('task7.3') }}
    />
    ),

    'task7.5' : (
      <div className='content'>
        <MarkupBanner
          key='task7.5'
          selector="#scan-filter-anchor"
          markup={<ScanningScreen5Badges />}
          onClick={() => { setContentKey('task7.6') }}
          buttonText={'NEXT'}
          altButtonText={'BACK'}
          altOnClick={() => { setContentKey('task7.4') }}
          raw={
            <div>
              <p><b>Getting E-Ticketing Assistance</b></p>
            </div>
          }
        />
      </div>
    ),

    'task7.6': (
      <div className='content'>
        <MarkupBanner
          key='task7.6'
          selector="#scan-filter-anchor"
          markup={<ScanningScreen5Badges />}
          onClick={() => { setContentKey('task7.7') }}
          buttonText={'NEXT'}
          altButtonText={'BACK'}
          altOnClick={() => { setContentKey('task7.5') }}
          raw={
            <div>
              <p>Immediate assistance with E-Ticketing issues onsite is available to Event Organizers, Game Masters, and attendees at the nearest Event HQ. Event HQs may also call in the E-Ticketing Support Team if needed. Attendees can also get assistance with any ticketing or badge issues at Customer Service.</p>
            </div>
          }
        />
      </div>
    ),

    'task7.7': (
      <div className='content'>
        <MarkupBanner
          key='task7.7'
          selector="#scan-filter-anchor"
          markup={<ScanningScreen5Badges />}
          onClick={() => { setContentKey('task7.8') }}
          buttonText={'NEXT'}
          altButtonText={'BACK'}
          altOnClick={() => { setContentKey('task7.6') }}
          raw={
            <div>
              <p>You can also email events@gencon.com with E-Ticketing questions anytime. Response times may be slower during the convention.</p>
            </div>
          }
        />
      </div>
    ),

    'task7.8': (
      <div className='content'>
        <MarkupBanner
          key='task7.8'
          selector="#scan-filter-anchor"
          markup={<ScanningScreen5Badges />}
          onClick={async () => { await completeTask('dealing-with-problems'); setContentKey('task7.9') }}
          buttonText={'NEXT'}
          altButtonText={'BACK'}
          altOnClick={() => { setContentKey('task7.7') }}
          raw={
            <div>
              <p>When requesting support, please include at least the following information:</p>
              <ul>
                <li>The event info, including event code, time, and name of event</li>
                <li>A clear photo of the badge including the badge ID and attendee name</li>
                <li>Technology used for scanning electronic tickets</li>
                <li>Any special circumstances we should know about</li>
              </ul>
            </div>
          }
        />
      </div>
    ),

    'task7.9': (
      <div className='content'>
        <MarkupBanner
          key='task7.9'
          selector="#scan-filter-anchor"
          markup={<ScanningScreen5Badges />}
          buttonText={'Next Scenario'}
          altButtonText={'BACK'}
          onClick={() => { setContentKey('task8.1'); }}
          altOnClick={() => { setContentKey('task7.8') }}
          raw={
            <div>
              <p>Now you know how to deal with some of the problems you might encounter while managing E-Ticketed events.</p>
              <p>Now, let’s continue with the next scenario.</p>
            </div>
          }
        />
      </div>
    ),


    'task8.1': (
      <div className='content'>
        <h2 className='section-title'>Redeeming More Guest E-Tickets</h2>
        <DemoTicketRedemptions
          contentKey={contentKey}
          setContentKey={setContentKey}
          completeTask={completeTask}
          scans={scans}
          setScans={setScans}
        />
        <Banner
          raw={
            <div>
              <p>Sometimes an attendee's guest is not present when you first scan the attendee's badge. In this scenario Allie Three-Tix's last guest has just arrived so you need to get back into guest scanning mode for Allie.</p>
            </div>
          
          }
          buttonText={'NEXT'}
          onClick={() => { setContentKey('task8.2') }}
        />
      </div>
    ),

    'task8.2': (
      <div className='content'>
        <h2 className='section-title'>Redeeming More Guest E-Tickets</h2>
        <DemoTicketRedemptions
          contentKey={contentKey}
          setContentKey={setContentKey}
          completeTask={completeTask}
          scans={scans}
          setScans={setScans}
          scanningDisabled={false}
        />
        <Banner
          raw={
            <div>
              <p>To transfer Allie's guest ticket:</p>
              <ul>
                <li>Select Allie's name from the list (tip: you can bring her to the top of the list by re-scanning her badge)</li>
                <li>Select "Scan Guest Badges" button</li>
              </ul>
            <p>Scan the badge of Allie's other guest, <b>Malcolm Guest</b>, using these steps</p>
            </div>
          }
          icon={'fa fa-scanner fa-2x'}
        />
      </div>
    ),

    'task8.2-error': (
      <div className='content'>
        <h2 className='section-title'>Redeeming More Guest E-Tickets</h2>
        <Modal
          title={'Wrong Badge Scanned'}
          content={<div><div className='banner-button-group'><i className='fa fa-scanner fa-2x'></i></div><p>Incorrect Badge. Please Scan <b>Malcolm Guest</b>.</p></div>}
        />
        <DemoTicketRedemptions
          contentKey={contentKey}
          setContentKey={setContentKey}
          completeTask={completeTask}
          scans={scans}
          setScans={setScans}
          scanningDisabled={false}
        />
      </div>
    ),

    'task8.2-error2': (
      <div className='content'>
        <h2 className='section-title'>Redeeming More Guest E-Tickets</h2>
        <Modal
          title={'Not in Guest Scanning Mode'}
          content={<div><div className='banner-button-group'><i className='fa fa-scanner fa-2x'></i></div><p>You should be in Guest Scanning mode. Please enter Guest Scanning mode and then scan <b>Malcolm Guest</b>.</p></div>}
          buttonText={'OK'}
          onClick={() => { setContentKey('task8.2') }}
        />
        <DemoTicketRedemptions
          contentKey={contentKey}
          setContentKey={setContentKey}
          completeTask={completeTask}
          scans={scans}
          setScans={setScans}
          scanningDisabled={false}
        />
      </div>
    ),

    'task8.3': (
      <div className='content'>
        <h2 className='section-title'>Redeeming More Guest E-Tickets</h2>
        <DemoTicketRedemptions
          contentKey={contentKey}
          setContentKey={setContentKey}
          completeTask={completeTask}
          scans={scans}
          setScans={setScans}
        />
        <Banner
          raw={
            <div>
              <p>Congratulations! You've transferred and redeemed an attendee's second guest e-ticket. When all of the attendee's guest tickets have been redeemed the interface will automatically exit Guest Scanning mode.</p>
              <p>Now, let's continue with the next scenario.</p>
            </div>
          }
          buttonText={'Next Scenario'}
          onClick={() => setContentKey('task9.1') }
          altButtonText={'BACK'}
          altOnClick={() => { setScans([...scans.slice(1)]); setContentKey('task8.2') }}
        />
      </div>
    ),

    'task9.1': (
      <div className='content'>
        <MarkupBanner
          key='task9.1'
          markup={<ScanningForAllMarkup />}
          selector={'#scan-bar-anchor'}
          onClick={() => { setContentKey('task9.2') }}
          buttonText={'NEXT'}
          marginTop='40%'
          raw={
            <div>
              <p>If you're checking in attendees for many events at the same time you may find it easier to use the <b>Scan For All My Events</b> interface.
              With this interface you don’t have to switch between events in order to redeem tickets for them. When you scan an attendee’s badge with the <b>Scan For All My Events</b> interface it finds which of your events the attendee has an e-ticket for.
              </p>
            </div>
          }
        />
      </div>
    ),

    'task9.2': (
        <MarkupBanner
          key='task9.2'
          markup={<ScanningForAllModalMarkup />}
          selector={'#scan-bar-anchor'}
          onClick={() => { setContentKey('task9.3') }}
          buttonText={'NEXT'}
          altButtonText={'BACK'}
          altOnClick={() => { setContentKey('task9.1') }}
          marginTop='30em'
          raw={
            <div>
              <p>If the attendee has tickets for more than one of your events the interface will show you a list of the events that tickets can be redeemed for so you can select the correct event.</p>
              <p>The <b>Scan For All My Events</b> interface works very similar to the event specific scan interface that you’ve already learned about, except you cannot sell or comp tickets through it. In order to sell or comp a ticket to an attendee you must scan their badge from the event’s scan interface.</p>
            </div>
          }
        />
    ),

    'task9.3': (
      <MarkupTooltip
        key='task9.3'
        markup={<MyScheduleMarkup />}
        selector={'#scan-for-all-button'}
        onClick={async () => { await completeTask('scan-all-events'); setContentKey('task10.1') }}
        buttonText={'NEXT'}
        altButtonText={'BACK'}
        altOnClick={() => { setContentKey('task9.2') }}
        marginTop='40%'
        raw={
          <div>
            <p>You access the <b>Scan For All My Events</b> interface by going to your <b>“My Schedule”</b> page and then selecting the <b>“Scan For All My Events”</b> button at the top of your schedule. This interface is only visible if you are hosting an E-Ticketed event.</p>
          </div>
        }
      />
    ),

    'task10.1': (
      <div className='content'>
        <SectionTitle title='CONGRATULATIONS!' />
        <div className='content-top'>
          <p><b>You have completed Electronic Ticketing Training.</b></p>
          <p>From here you can go directly back to any of the training scenarios at any time, maybe try them with a different interface. You also now have access to the E-Ticketing Sandbox, where you can scan the training badges again without all the instructions. Please share this training walkthrough with your fellow event hosts.</p>
          <NavButtons buttonText={'Go To Sandbox'} onClick={() => { setContentKey('sandbox'); setChecklistOpen(true) }} />
        </div>
      </div>
    ),

    'prompt_reset_task5' : (
      <div className='content'>
      <h2 className='section-title'>Redeeming Guest E-Tickets</h2>
      <Modal
        title={'Repeat Task'}
        content={'You have already started / completed this task. Would you like to reset it?'}
        buttonText={'Yes'}
        onClick={() => { 
          setScans([badgeCodes[0]]);
          setContentKey('task5.1') }}
        altButtonText={'No'}
        altOnClick={() => { setContentKey('task5.1') }}
      />
      <DemoTicketRedemptions
        contentKey={contentKey}
        setContentKey={setContentKey}
        completeTask={completeTask}
        scans={scans}
        setScans={setScans}
      />
    </div>
    ),

    'prompt_reset_task7' : (
      <div className='content'>
        <h2 className='section-title'>Redeeming a Guest E-ticket Out of Order</h2>
        <Modal
          title={'Repeat Task'}
          content={'You have already started / completed this task. Would you like to reset it?'}
          buttonText={'Yes'}
          onClick={() => { 
            let redeemedJulie = badgeCodes[3];
            redeemedJulie.state = 'redeemed';
            redeemedJulie.message = 'Guest of Allie Three-Tix';
            let redeemedAllie = badgeCodes[2];
            redeemedAllie.additional_tickets = 1;
            setScans([badgeCodes[7], badgeCodes[2], badgeCodes[3], badgeCodes[0]]);
            setContentKey('task7.1') }}
          altButtonText={'No'}
          altOnClick={() => { setContentKey('task7.1') }}
        />
        <DemoTicketRedemptions
          contentKey={contentKey}
          setContentKey={setContentKey}
          completeTask={completeTask}
          scans={scans}
          setScans={setScans}
        />
      </div>
    ),

    'sandbox': (
      <div className='content'>
        <h2 className='section-title'>Sandbox Mode</h2>
        <DemoTicketRedemptions
          contentKey={contentKey}
          setContentKey={setContentKey}
          completeTask={completeTask}
          scans={scans}
          setScans={setScans}
          sandbox={true}
          scanningDisabled={false}
        />
      </div>
    ),

    'sandbox_confirm' : (
        <ScanningTooltipView
          title='Selling and Comping E-Tickets'
          content={'The purchaser will get an email with a link to approve the purchase or they can go directly to their cart page on gencon.com where they can see and approve the e-ticket purchase at the top of the page.'}
          position={{ right: '17%', top: '14.5rem' }}
          onClick={() => { setContentKey('sandbox'); setScans(scans.map(scan => { if (scan.state === 'pending_sale') { scan.state = 'redeemed'; scan.message = '' } return scan; })) }}
          buttonContent={'APPROVE PURCHASE'}
          direction={'left'}
          contentKey={contentKey}
          setContentKey={setContentKey}
          completeTask={completeTask}
          scans={scans}
          setScans={setScans}
        />
      ),

    'force-mobile': (
      <div className='content'>
        <div className='content-top'>
          <h2 className='section-title'>What Device are you using?</h2>
          <p>To redeem E-tickets on a mobile device you must install the Gen Con mobile app and log on. </p>
          <p>To continue E-ticketing training after installing the app:</p>
          <ul>
            <li>Click on the Host button</li>
            <li>Click on the Training button to start training</li>
          </ul>
          <NavButtons onClick={() => setContentKey('web3.2')} />
        </div>
      </div>
    )
  }


  return (
    <div className='wrapper-width-right-side'>
      <div className='training-panel-wrapper panel'>
        {(isMobileScreen && !contentKey.includes('web')) ?
          content['force-mobile']
          :
          content[contentKey]
        }
      </div>
    </div>
  )
}

export default Content;