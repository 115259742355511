import React from "react";

export default function ScanningScreen5Badges() {
  return (
  <div className="" id="page" style={{width: '100%'}}>
  <div className="page-title">RPG Event 1</div>
  <div className="page-title-details">
    <span>
      <i className="fa fa-clock-o" />
      Thursday, 8:00 AM EST for 1 hr
    </span>
  </div>
  <div className="panel">
    <div className="ribbon-top">
      <ul className="left">
        <li className="btn">
          <a href="/event_finder?c=indy2025">
            <i className="fa fa-search fa-2x" />
            <label>Find Events</label>
          </a>
        </li>
        <li className="btn">
          <a href="/events/14">
            <i className="fa fa-list-ul fa-2x" />
            <label>Details</label>
          </a>
        </li>
        <li className="btn">
          <a href="/events/14/tickets">
            <i className="fa fa-ticket-e fa-2x" />
            <label>Tickets</label>
          </a>
        </li>
        <li className="btn" id="scans-anchor">
          <a className="pressed" href="/events/14/scans">
            <i className="fa fa-scanner fa-2x" />
            <label>Scans</label>
          </a>
        </li>
        <li className="btn">
          <a href="/events/14/messages">
            <i className="fa fa-comments fa-2x" />
            <label>Messages</label>
          </a>
        </li>
      </ul>
    </div>
    <div
      data-props='{"eventId":14,"hostId":2,"scanWindowOpen":true,"actionCableOn":true}'
      className="ticket-redemptions-component"
    >
      <div className="redemptions-container">
        <div id='scan-bar-anchor'>
          <div className="attendance-scan-bar attendance-scan-prompt">
            <span>Ready to scan a badge &nbsp;</span>
            <i className="fa fa-scanner fa-2x" title="Scan a badge" />
          </div>
        </div>
        <div id="Scanner" />
        <div className="scan-filter-bar" id='scan-filter-anchor'>
          <div className="scan-filter-button">
            <span className="scan-filter-box">
              <i className="e-ticket-success-color fa fa-check fa-2x" />
              <div className="scan-filter-count">1</div>
            </span>
          </div>
          <div className="scan-filter-separator" />
          <div className="scan-filter-button">
            <span className="scan-filter-box">
              <i className="e-ticket-pending-color fa fa-refresh fa-2x" />
              <div className="scan-filter-count">1</div>
            </span>
          </div>
          <div className="scan-filter-separator" />
          <div className="scan-filter-button">
            <span className="scan-filter-box">
              <i className="e-ticket-attention-color fa fa-alert fa-2x" />
              <div className="scan-filter-count">2</div>
            </span>
          </div>
          <div className="scan-filter-separator" />
          <div className="scan-filter-button">
            <span className="scan-filter-box">
              <i className="e-ticket-error-color fa fa-exclamation fa-2x" />
              <div className="scan-filter-count">1</div>
            </span>
          </div>
        </div>
        <div className="infinite-scroll-component__outerdiv">
          <div
            className="infinite-scroll-component "
            style={{ height: "auto", overflow: "auto" }}
          >
            <div>
              <table className="scan-ticket-table">
                <tbody style={{ columnCount: 5 }}>
                  <tr className="scan-filter-row">
                    <td style={{ width: "80%" }}>
                      <span className="ticketholder-name">
                        <div>Clyde Guzuta</div>
                        <div className="message">
                          Attendee does not have a badge with access to this
                          event
                        </div>
                      </span>
                    </td>
                    <td
                      style={{
                        width: "20%",
                        textAlign: "right",
                        paddingRight: "1em"
                      }}
                    >
                      <span className="ticket-count-container">
                        <div className="ticket-count-num" />
                        <i className="fa e-ticket-error-color fa-badge-error fa-3x" />
                      </span>
                    </td>
                  </tr>
                  <tr className="scan-filter-row">
                    <td style={{ width: "80%" }}>
                      <span className="ticketholder-name">
                        <div>Casper McFadden</div>
                        <div className="message">
                          No tickets found - select for options
                        </div>
                      </span>
                    </td>
                    <td
                      style={{
                        width: "20%",
                        textAlign: "right",
                        paddingRight: "1em"
                      }}
                    >
                      <span className="ticket-count-container">
                        <div className="ticket-count-num" />
                        <i className="fa e-ticket-attention-color fa-no-ticket fa-3x" />
                      </span>
                    </td>
                  </tr>
                  <tr className="scan-filter-row">
                    <td style={{ width: "80%" }}>
                      <span className="ticketholder-name">
                        <div>Malcom Crow</div>
                        <div className="message">
                          Ticket transaction awaiting approval by Malcom Crow
                        </div>
                      </span>
                    </td>
                    <td
                      style={{
                        width: "20%",
                        textAlign: "right",
                        paddingRight: "1em"
                      }}
                    >
                      <span className="ticket-count-container">
                        <div className="ticket-count-num" />
                        <i id='icon-explain-anchor' className="fa e-ticket-pending-color fa-ticket-in-cart fa-3x" />
                      </span>
                    </td>
                  </tr>
                  <tr className="scan-filter-row">
                    <td style={{ width: "80%" }}>
                      <span className="ticketholder-name">
                        <div>Allie Durango</div>
                        <div className="message">
                          Allie has 1 guest ticket remaining. Select to scan
                          guests.
                        </div>
                      </span>
                    </td>
                    <td
                      style={{
                        width: "20%",
                        textAlign: "right",
                        paddingRight: "1em"
                      }}
                    >
                      <span className="ticket-count-container">
                        <div className="ticket-count-num">+1</div>
                        <i className="fa e-ticket-attention-color fa-ticket-horz-blank fa-3x" />
                      </span>
                    </td>
                  </tr>
                  <tr className="scan-filter-row">
                    <td style={{ width: "80%" }}>
                      <span className="ticketholder-name">
                        <div>Fred D'Allie</div>
                      </span>
                    </td>
                    <td
                      style={{
                        width: "20%",
                        textAlign: "right",
                        paddingRight: "1em"
                      }}
                    >
                      <span className="ticket-count-container">
                        <div className="ticket-count-num" />
                        <i className="fa e-ticket-success-color fa-ticket-checked fa-3x" />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
)};
