import React, { useEffect, useState, useRef } from 'react';
import Banner from "./Banner";

const uninteractableStyles = {
  pointerEvents: 'none',
  userSelect: 'none',
  WebkitUserSelect: 'none',
  MozUserSelect: 'none',
  msUserSelect: 'none',
  cursor: 'default',
};

const interactableStyles = {
  pointerEvents: 'auto',
  userSelect: 'auto',
  WebkitUserSelect: 'auto',
  MozUserSelect: 'auto',
  msUserSelect: 'auto',
  cursor: 'pointer',
};

const MarkupBanner = ({
  markup,
  selector,
  title,
  content,
  buttonText,
  onClick,
  altButtonText,
  altOnClick,
  raw,
  marginTop = '50%',
  marginRight = '0'
}) => {
  const containerRef = useRef(null);
  const targetRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let timeoutId;

    const findAndSetupTarget = () => {
      if (!containerRef.current || !selector) return;

      const element = containerRef.current.querySelector(selector);
      if (element) {
        console.log('Found target element:', selector);
        targetRef.current = element;
        setIsVisible(true);
      } else {
        console.log('No element found matching selector:', selector);
      }
    };

    // Initial setup
    timeoutId = setTimeout(findAndSetupTarget, 100);

    // Cleanup
    return () => {
      clearTimeout(timeoutId);
      if (targetRef.current) {
        Object.assign(targetRef.current.style, uninteractableStyles);
      }
    };
  }, [selector]);

  const handleClick = (handler) => {
    setIsVisible(false);
    if (targetRef.current) {
      Object.assign(targetRef.current.style, uninteractableStyles);
    }
    if (handler) {
      handler();
    }
  };

  return (
    <div ref={containerRef} style={{ position: 'relative' }}>
      <div style={uninteractableStyles}>{markup}</div>
      {isVisible && (
        <div style={{ 
          position: 'absolute', 
          top: 0, 
          left: 0, 
          width: '100%', 
          height: '100%',
          zIndex: 1000 
        }}>
          <div className="content">
            {title && <h2 className="slide-title">{title}</h2>}
            <Banner
              style={{
                position: 'relative',
                marginTop: marginTop,
                zIndex: 1000
              }}
              content={content}
              buttonText={buttonText ?? 'GOT IT'}
              onClick={() => handleClick(onClick)}
              altButtonText={altButtonText}
              altOnClick={() => handleClick(altOnClick)}
              raw={raw}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MarkupBanner;