import React, { useState } from 'react';
import Modal from 'react-modal';

import OuterFilterCheckbox from './OuterFilterCheckbox';
import InnerFilterCheckbox from './InnerFilterCheckbox';
import SelectAllCheckbox from './SelectAllCheckbox';

const Filters = ({ searchFiltersRef, updateBadges, filterChoices }) => {
  const [innerFilters, setInnerFilters] = useState([]);
  const [outerFilter, setOuterFilter] = useState('');
  const [outerFilterOpen, setOuterFilterOpen] = useState(true);
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);
  const [allChecked, setAllChecked] = useState(true);

  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 100000,
      bottom: 0,
      backgroundColor: 'rgba(10, 10, 10, 0.60)',
    },
    content: {
      border: 'solid #bca98b 1px',
      background: '#f4f1ec',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '7px',
      outline: 'none',
      padding: '0',
    },
  };

  const loadInnerFilters = (outerFilterId) => {
    let _innerFilters = [];
    filterChoices[outerFilterId].forEach(item => {
      _innerFilters.push({
        name: item.name,
        value: item.value,
        checked: searchFiltersRef.current[outerFilterId].map(i => i.value).includes(item.value),
      });
    });
    setOuterFilter(outerFilterId);
    setInnerFilters(_innerFilters);
    setOuterFilterOpen(false);
    setAllChecked(searchFiltersRef.current[outerFilterId].length === 0);
  }

  const loadOuterFilters = () => {
    setInnerFilters([]);
    setOuterFilterOpen(true);
  }

  const closeModal = () => {
    setFiltersModalOpen(false);
    updateBadges();
  }

  const isAllChecked = (outerFilterId) => {
    let _allChecked =
      searchFiltersRef.current[outerFilterId].length === 0 ||
      searchFiltersRef.current[outerFilterId].length ===
        filterChoices[outerFilterId].length;
    if (_allChecked && !allChecked) {
      let _innerFilters = [];
      filterChoices[outerFilterId].forEach(item => {
        _innerFilters.push({ name: item.name, value: item.value, checked: false });
      });
      searchFiltersRef.current[outerFilterId] = [];
      setInnerFilters(_innerFilters);
      setAllChecked(_allChecked);
    } else {
      loadInnerFilters(outerFilterId);
    }
  }

  const onSelectAllClick = (outerFilterId) => {
    searchFiltersRef.current[outerFilterId] = [];
    isAllChecked(outerFilterId);
  }

  const onInnerFilterClick = (outerFilterId, innerFilter) => {  
    if (searchFiltersRef.current[outerFilterId].map(i => i.value).includes(innerFilter.value)) {
      searchFiltersRef.current[outerFilter] = searchFiltersRef.current[outerFilter].filter(e => e.value !== innerFilter.value);
    } else {
      searchFiltersRef.current[outerFilter].push(innerFilter);
    }
    isAllChecked(outerFilterId);
  }

  const onClearAllClick = () => {
    Object.keys(searchFiltersRef.current).forEach(key => {
      searchFiltersRef.current[key] = [];
    });
    updateBadges();
  }

  return (
    <div className="finder-filters labeled">
      <button
        onClick={() => setFiltersModalOpen(true)}
        className="search-filter button"
        title="Filter Search"
      >
        <div class="flex flex-center">
          <i className="fa-filter"></i>
          <label>Filter</label>
        </div>
      </button>

      <button
        onClick={onClearAllClick}
        className="search-filter button"
        title="Clear Filters"
      >
       <div class="flex flex-center">
        <i className="fa-times-circle-o"></i>
        <label>Clear All</label>
       </div>
      </button>

      <Modal isOpen={filtersModalOpen} style={customStyles} ariaHideApp={false}>
        <div>
          {outerFilterOpen ? (
            // outer filters
            <div>
              <OuterFilterCheckbox
                id="status"
                title="Event Status"
                searchFiltersRef={searchFiltersRef}
                loadInnerFilters={loadInnerFilters}
              />
              <OuterFilterCheckbox
                id="type"
                title="Event Type"
                searchFiltersRef={searchFiltersRef}
                loadInnerFilters={loadInnerFilters}
              />
              <OuterFilterCheckbox
                id="gameSystem"
                title="Game System"
                searchFiltersRef={searchFiltersRef}
                loadInnerFilters={loadInnerFilters}
              />
              <OuterFilterCheckbox
                id="rulesEdition"
                title="Rules Edition"
                searchFiltersRef={searchFiltersRef}
                loadInnerFilters={loadInnerFilters}
              />
              <OuterFilterCheckbox
                id="gamemasters"
                title="Gamemasters"
                searchFiltersRef={searchFiltersRef}
                loadInnerFilters={loadInnerFilters}
              />
              <OuterFilterCheckbox
                id="date"
                title="Date"
                searchFiltersRef={searchFiltersRef}
                loadInnerFilters={loadInnerFilters}
              />
              <OuterFilterCheckbox
                id="time"
                title="Start Time"
                searchFiltersRef={searchFiltersRef}
                loadInnerFilters={loadInnerFilters}
              />
              <OuterFilterCheckbox
                id="price"
                title="Ticket Price"
                searchFiltersRef={searchFiltersRef}
                loadInnerFilters={loadInnerFilters}
              />
              <OuterFilterCheckbox
                id="ticketingMethod"
                title="Ticketing Method"
                searchFiltersRef={searchFiltersRef}
                loadInnerFilters={loadInnerFilters}
              />

              <div className="event-filter-close">
                <button onClick={closeModal} className="button">
                  Done
                </button>
              </div>
            </div>
          ) : (
            // inner filters
            <div>
              <div className="modal-filters no-title">
                <SelectAllCheckbox
                  checked={allChecked}
                  count={filterChoices[outerFilter].length}
                  outerFilter={outerFilter}
                  onClick={onSelectAllClick}
                />
                {innerFilters.map(item => (
                  <InnerFilterCheckbox
                    key={item.value}
                    filter={item}
                    outerFilter={outerFilter}
                    handleClick={onInnerFilterClick}
                  />
                ))}
              </div>

              <div className="event-filter-close">
                <button onClick={loadOuterFilters} className="button">
                  OK
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Filters;
