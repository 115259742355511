import React, { useState, useEffect, useRef } from 'react';
import Scanner from '../scanner/Scanner';
import ScannerBar from './ScannerBar';
import FilterBar from './FilterBar';
import ScanRow from './ScanRow';
import ManualScanModal from './ManualScanModal';


const DemoTicketRedemptions = ({ contentKey, setContentKey, completeTask, scans, setScans, sandbox = false, scanningDisabled = true, initialGuestScanning={enabled: false, host: {}}}, initialOptionsOpenID=null) => {
  const [focus, setFocus] = useState(document.hasFocus()); // indicates if page has focus / tab is active
  const [showScans, setShowScans] = useState([]); // list of ScanToRedeem objects currently visible on page
  const [scanCounts, setScanCounts] = useState({ redeemed: 0, partially_redeemed: 0, guest_redeemed: 0, can_issue: 0, can_over_issue: 0, can_comp: 0, can_over_comp: 0, can_sell_or_comp: 0, can_over_sell_or_comp: 0, pending_sale: 0, event_selection: 0, past_event: 0, future_event: 0, ticket_error: 0, event_error: 0, badge_error: 0, approval_pending: 0, approval_denied: 0 }); // overall ScanToRedeem state counts
  const [filterList, setFilterList] = useState([]); // list of ScanToRedeem states currently filtering by
  const [guestScanning, setGuestScanning] = useState(initialGuestScanning); // controls if guest scanning is active, control with toggleGuestScanning
  const [showSpinner, setShowSpinner] = useState(false); // toggle to show spinner
  const [optionsOpenID, setOptionsOpenID] = useState(initialOptionsOpenID);
  const [showManualScan, setShowManualScan] = useState(false); // toggle to show manual scan modal
  const hostScanning = false;

  const badgeCodes = [
    { id: 1, badge_code: 'B-96JDLZ88GDEY', state: 'redeemed', message: '', ticketholder: { first_name: 'Fred', last_name: 'One-Tic' }, host_ticketholder:{ first_name: 'Fred', last_name: 'One-Tic' }, event_choices: [], additional_tickets: 0, updated_at: '2020-01-01T00:00:00.000Z' },
    { id: 2, badge_code: 'B-YWG5L1EEWPQN', state: 'redeemed', message: '', ticketholder: { first_name: 'Flora', last_name: 'One-Tic' }, host_ticketholder: { first_name: 'Flora', last_name: 'One-Tic' }, event_choices: [], additional_tickets: 0, updated_at: '2020-01-01T00:00:00.000Z' },
    { id: 3, badge_code: 'B-9ER5QZ771RRN', state: 'partially_redeemed', message: 'Allie has guest tickets remaining - select to scan', ticketholder: { first_name: 'Allie', last_name: 'Three-Tix' }, host_ticketholder: { first_name: 'Allie', last_name: 'Three-Tix' }, event_choices: [], additional_tickets: 2, updated_at: '2020-01-01T00:00:00.000Z' },
    { id: 4, badge_code: 'B-NQQ3VD88GQPN', state: '', message: '', ticketholder: { first_name: 'Julie', last_name: 'Guest' },   host_ticketholder: { first_name: 'Julie', last_name: 'Guest' }, event_choices: [], additional_tickets: 0, updated_at: '2020-01-01T00:00:00.000Z' },
    { id: 5, badge_code: 'B-NVMGLWPPOMRN', state: '', message: '', ticketholder: { first_name: 'Malcolm', last_name: 'Guest' }, host_ticketholder: { first_name: 'Malcolm', last_name: 'Guest' }, event_choices: [], additional_tickets: 0, updated_at: '2020-01-01T00:00:00.000Z' },
    { id: 6, badge_code: 'B-Y80KZWJJ8019', state: '', message: '', ticketholder: { first_name: 'Casper', last_name: 'No-Tix' }, host_ticketholder: { first_name: 'Natalie', last_name: 'Money' }, event_choices: [], additional_tickets: 0, updated_at: '2020-01-01T00:00:00.000Z' },
    { id: 7, badge_code: 'B-9M0EKVPP30LN', state: '', message: '', ticketholder: { first_name: 'Ingrid', last_name: 'No-Tix' }, host_ticketholder: { first_name: 'Natalie', last_name: 'Money' }, event_choices: [], additional_tickets: 0, updated_at: '2020-01-01T00:00:00.000Z' },
    { id: 8, badge_code: 'B-Y0R5G811ERE9', state: 'badge_error', message: 'Attendee does not have a badge with access to this event', ticketholder: { first_name: 'Clyde', last_name: 'Bad-Badge' }, host_ticketholder: { first_name: 'Clyde', last_name: 'Bad-Badge' }, event_choices: [], additional_tickets: 0, updated_at: '2020-01-01T00:00:00.000Z' },
  ]

  useEffect(() => {
    setScanCounts({
      redeemed: scans.filter(scan => scan.state === 'redeemed').length,
      partially_redeemed: scans.filter(scan => scan.state === 'partially_redeemed').length,
      guest_redeemed: scans.filter(scan => scan.state === 'guest_redeemed').length,
      can_issue: scans.filter(scan => scan.state === 'can_issue').length,
      badge_error: scans.filter(scan => scan.state === 'badge_error').length,
      can_over_issue: scans.filter(scan => scan.state === 'can_over_issue').length,
      can_comp: scans.filter(scan => scan.state === 'can_comp').length,
      can_over_comp: scans.filter(scan => scan.state === 'can_over_comp').length,
      can_sell_or_comp: scans.filter(scan => scan.state === 'can_sell_or_comp').length,
      can_over_sell_or_comp: scans.filter(scan => scan.state === 'can_over_sell_or_comp').length,
      pending_sale: scans.filter(scan => scan.state === 'pending_sale').length,
      event_selection: scans.filter(scan => scan.state === 'event_selection').length,
      past_event: scans.filter(scan => scan.state === 'past_event').length,
      future_event: scans.filter(scan => scan.state === 'future_event').length,
      ticket_error: scans.filter(scan => scan.state === 'ticket_error').length,
      event_error: scans.filter(scan => scan.state === 'event_error').length,
      approval_pending: scans.filter(scan => scan.state === 'approval_pending').length,
      approval_denied: scans.filter(scan => scan.state === 'approval_denied').length,
    });
  }, [scans]);

  useEffect(() => {
    if (filterList.length > 0) {
      setShowScans(scans.filter(scan => filterList.includes(scan.state)));
    } else {
      setShowScans(scans);
    }
  }, [scans, filterList]);

  useEffect(() => {
    setFocus(document.hasFocus());
  }, [scanningDisabled]);


  useEffect(() => {
    if (contentKey.startsWith('task4.1')) {
      setScans([...scans.filter(scan => scan.badge_code !== 'B-96JDLZ88GDEY')]);
    }
    if (contentKey.startsWith('task5.1')) {
      setScans([...scans.filter(scan => scan.badge_code !== 'B-9ER5QZ771RRN' && scan.badge_code !== 'B-NQQ3VD88GQPN')]);
    }
    if (contentKey.startsWith('task5.4')) {
      setScans([badgeCodes[2], ...scans.filter(scan => scan.badge_code !== 'B-9ER5QZ771RRN' && scan.badge_code !== 'B-NQQ3VD88GQPN')]);
    }
    if (contentKey.startsWith('task5.4-error')) {
      if (contentKey.startsWith('task5.4-error2')) {
      }else {
        toggleGuestScanning(badgeCodes[2].id);
        setOptionsOpenID(badgeCodes[2].id);
      }
    }
    if (contentKey.startsWith('task5.5')) {
      if (!guestScanning.enabled) {
        setGuestScanning({ enabled: true, host: badgeCodes[2] });
        setOptionsOpenID(badgeCodes[2].id);
      }
    }
    if (contentKey.startsWith('task6.1')) {
      setScans([...scans.filter(scan => scan.badge_code !== 'B-Y80KZWJJ8019')]);
    }
    if (contentKey.startsWith('task6.2')) {
      setOptionsOpenID(null);
    }
    if (contentKey.startsWith('task5.4b')) {
      setOptionsOpenID(badgeCodes[2].id);
    }
    if (contentKey.startsWith('task6.3')) {
      setOptionsOpenID(badgeCodes[5].id);
    }
    if (contentKey.startsWith('task6.5')) {
      setOptionsOpenID(badgeCodes[5].id);
    }
    if (contentKey.startsWith('task7.1')) {
      setScans([...scans.filter(scan => scan.badge_code !== 'B-Y0R5G811ERE9')]);
    }
    if (contentKey.startsWith('task8.1') || contentKey.startsWith('task8.2')) {
      let partial = badgeCodes[2];
      partial.state = 'partially_redeemed';
      partial.additional_tickets = 1;
      setScans([partial, ...scans.filter(scan => scan.badge_code !== 'B-9ER5QZ771RRN' && scan.badge_code !== 'B-NVMGLWPPOMRN')]);
    }
    if (contentKey.startsWith('task8.2-error')) {
      if (contentKey.startsWith('task8.2-error2')) {
      }else {
        toggleGuestScanning(badgeCodes[2].id);
        setOptionsOpenID(badgeCodes[2].id);
      }
    }
  }, [contentKey]);

  const dismissScan = async (id) => {
    if (contentKey.startsWith('task6.5')) { setContentKey('task6.5-error'); return }
    if (!(contentKey.startsWith('sandbox') || contentKey.startsWith('task6.5'))) { return }
    setShowSpinner(true);
    setScans(scans.filter(scan => scan.id !== id));
    setShowSpinner(false);
    if (contentKey.startsWith('task6.5')) {
      setContentKey('task6.1');
    }
  };

  const issueTicket = async (id, oversell, comp) => {
    if (!(contentKey.startsWith('sandbox') || contentKey.startsWith('task6.5'))) { return; }
    setShowSpinner(true);
    await new Promise(r => setTimeout(r, 250));

    if (comp) {
      if (contentKey.startsWith('task6.5')) { setContentKey('task6.5-error'); return }
      let scan = scans.find(scan => scan.id === id);
      scan.state = 'redeemed';
      scan.message = '';
      scan.additional_tickets = 0;
      setScans([scan, ...scans.filter(scan => scan.id !== id)]);
      setShowSpinner(false);
      if (!contentKey.startsWith('sandbox')) {
        setContentKey('task6.7');
      }
    } else {
      let scan = scans.find(scan => scan.id === id);
      scan.state = 'pending_sale';
      scan.message = 'Ticket transaction awaiting approval by Natalie Money';
      scan.additional_tickets = 0;
      setScans([scan, ...scans.filter(scan => scan.id !== id)]);
      setShowSpinner(false);
      if (!contentKey.startsWith('sandbox')) {
        setContentKey('task6.6');
      } else {
        setContentKey('sandbox_confirm');
      }
    }
  };

  const sortScans = (scans) => {
    return scans.sort((a, b) => new Date(a.updated_at) < new Date(b.updated_at) ? 1 : -1);
  }

  const toggleFilters = (filters) => {
    if (filters.some(filter => filterList.includes(filter))) {
      setFilterList(filterList.filter(filter => !filters.includes(filter)));
    } else {
      setFilterList([...filterList, ...filters]);
    }
  };

  const toggleOptions = async (id) => {
    if (contentKey.startsWith('task6.2')) {
      setContentKey('task6.3');
      setOptionsOpenID(id);
      return;
    }
    if (id === optionsOpenID) {
      setOptionsOpenID(null);
    } else {
      setOptionsOpenID(id);
    }
    if (contentKey.startsWith('task8.4')) {
      setContentKey('task8.5');
      return;
    }
  }

  const pendingScan = (badgeCode) => {
    return { badge_code: badgeCode, state: 'pending_response', message: '', ticketholder: null, additional_tickets: 0, id: Math.random(), updated_at: new Date() };
  }

  const redeemForEvent = async (id, badgeCode, eventId) => { }

  const onScan = async (badgeCode) => {
    if (scanningDisabled) return;
    if (guestScanning.enabled) {
      if (badgeCode === 'B-9ER5QZ771RRN') {
        return reportScan(badgeCode);
      }
      if (contentKey.startsWith('task5.4') && badgeCode !== 'B-NQQ3VD88GQPN') {
        return reportScan(badgeCode);
      }
      if (contentKey.startsWith('task8.2') && badgeCode !== 'B-NVMGLWPPOMRN') {
        return reportScan(badgeCode);
      }
      let newScan = badgeCodes.find(badge => badge.badge_code === badgeCode);
      let hostScan = guestScanning.host;

      if (scans.find(scan => scan.badge_code === badgeCode)) {
        let existingScan = scans.find(scan => scan.badge_code === badgeCode);
        existingScan.message = 'Attendee’s ticket has already been redeemed';
        existingScan.updated_at = new Date();
        setScans([hostScan, existingScan, ...scans.filter(scan => scan.id !== newScan.id && scan.id !== hostScan.id && scan.id !== existingScan.id)]);
      } else if (newScan.state === 'redeemed' || newScan.state === 'partially_redeemed' || newScan.state === 'guest_redeemed') {
        if (scans.find(scan => scan.id === newScan.id)) {
          newScan.message = 'Attendee’s ticket has already been redeemed';
        } else {
          newScan.message = 'Guest already has a ticket for this event'
          newScan.state = 'badge_error';
        }
        newScan.updated_at = new Date();
        setScans([hostScan, newScan, ...scans.filter(scan => scan.id !== newScan.id && scan.id !== hostScan.id)]);
      } else if (newScan.state === 'badge_error') {
        newScan.message = 'Attendee does not have a badge with access to this event';
        newScan.updated_at = new Date();
        setScans([hostScan, newScan, ...scans.filter(scan =>  scan.id !== newScan.id && scan.id !== hostScan.id)]);
      } else {
        newScan.state = 'guest_redeemed';
        newScan.message = 'Guest of ' + hostScan.ticketholder.first_name + ' ' + hostScan.ticketholder.last_name;
        newScan.updated_at = new Date();
        hostScan.additional_tickets -= 1;
        hostScan.updated_at = new Date();

        if (hostScan.additional_tickets === 0) {
          if (optionsOpenID === hostScan.id) {
            setOptionsOpenID(null);
          }
          hostScan.state = 'redeemed';
          hostScan.message = '';
          setGuestScanning({ enabled: false, host: {} });
        }
        setScans([hostScan, newScan, ...scans.filter(scan => scan.badge_code !== newScan.badge_code && scan.badge_code !== hostScan.badge_code)]);
      }

    } else {
      let newScan = badgeCodes.find(badge => badge.badge_code === badgeCode);
      if (scans.find(scan => scan.badge_code === badgeCode)) {
        let existingScan = scans.find(scan => scan.badge_code === badgeCode);
        existingScan.message = 'Attendee’s ticket has already been redeemed';
        existingScan.updated_at = new Date();
        setScans([existingScan, ...scans.filter(scan => scan.id !== newScan.id && scan.id !== existingScan.id)]);
        await reportScan(badgeCode);
        return;
      }

      if (newScan.state === '') {
        newScan.state = 'can_sell_or_comp';
        newScan.message = 'No tickets found - select for options';
        newScan.updated_at = new Date();
      }
      if (newScan.state === 'redeemed' || newScan.state === 'partially_redeemed' || newScan.state === 'guest_redeemed') {
        if (scans.find(scan => scan.id === newScan.id)) {
          let newScan = badgeCodes.find(badge => badge.badge_code === badgeCode);
          setScans([newScan, ...scans.filter(scan => scan.id !== newScan.id)]);
        }
        setScans([newScan, ...scans.filter(scan => scan.id !== newScan.id)]);
      } else {
        setScans([newScan, ...scans.filter(scan => scan.id !== newScan.id)]);
      }
    }

    await reportScan(badgeCode);
  }

  const isAlreadyRedeemed = (scan) => { return scan.state === 'redeemed' || scan.state === 'partially_redeemed' || scan.state === 'guest_redeemed' };

  const reportScan = async (badgeCode) => {
    if (contentKey.startsWith('task4.1')) {
      if (badgeCode === 'B-96JDLZ88GDEY') {
        return setContentKey('task4.2');
      } 
      else {
        setScans([...scans.filter(scan => scan.badge_code !== badgeCode)]);
        return setContentKey('task4.1-error')
      }
    }
    if (contentKey.startsWith('task5.1')) {
      if (badgeCode === 'B-9ER5QZ771RRN') {
        return setContentKey('task5.2');
      } else {
        setScans([...scans.filter(scan => scan.badge_code !== badgeCode)]);
        return setContentKey('task5.1-error');
      }
    }
    if (contentKey.startsWith('task5.4')) {
      if (badgeCode === 'B-NQQ3VD88GQPN' && guestScanning.enabled) {
        return setContentKey('task5.5');
      } else {
        if (badgeCode === 'B-9ER5QZ771RRN') {
          if (guestScanning.enabled) {
            return setContentKey('task5.4-error');
          } else {
            return setContentKey('task5.4-error2');
          }
        }
        setScans([...scans.filter(scan => scan.badge_code !== badgeCode)]);
        if (guestScanning.enabled) {
          return setContentKey('task5.4-error');
        } else {
          return setContentKey('task5.4-error2');
        }
      }
    }
    if (contentKey.startsWith('task6.1')) {
      if (badgeCode === 'B-Y80KZWJJ8019') {
        return setContentKey('task6.2');
      } else {
        setScans([...scans.filter(scan => scan.badge_code !== badgeCode)]);
        return setContentKey('task6.1-error');
      }
    }
    if (contentKey.startsWith('task7.2')) {
      if (badgeCode === 'B-Y0R5G811ERE9') {
        return setContentKey('task7.3');
      } else {
        setScans([...scans.filter(scan => scan.badge_code !== badgeCode)]);
        return setContentKey('task7.2-error');
      }
    }
    if (contentKey.startsWith('task8.2')) {
      if (badgeCode === 'B-NVMGLWPPOMRN' && guestScanning.enabled) {
        await completeTask('redeeming-more-guest-tickets');
        return setContentKey('task8.3');
      } else {
        setScans([...scans.filter(scan => (scan.badge_code !== badgeCode || scan.badge_code !== ''))]);
        if (guestScanning.enabled) {
          return setContentKey('task8.2-error');
        } else {
          return setContentKey('task8.2-error2');
        }
      }
    }
  }

  const toggleGuestScanning = (id) => {
    if (guestScanning.enabled && id === guestScanning.host.id) {
      if (contentKey.startsWith('task5.4')) { return }
      if (contentKey.startsWith('task8.2')) { return }
      let hostScan = scans.find(scan => scan.id === id);
      setGuestScanning({ enabled: false, host: {} });
      if (optionsOpenID === id) {
        setOptionsOpenID(null);
      }
      setScans([hostScan, ...sortScans(scans).filter(scan => scan.badge_code !== hostScan.badge_code)])
      if (contentKey.startsWith('task5.5')) {
        completeTask('redeeming-guest-tickets');
        setContentKey('task5.6');
      }
    } else {
      let hostScan = scans.find(scan => scan.id === id);
      setGuestScanning({ enabled: true, host: hostScan });
      setScans([hostScan, ...sortScans(scans).filter(scan => scan.badge_code !== hostScan.badge_code)])
      if (contentKey.startsWith('task5.4') && !contentKey.startsWith('task5.4-error') ) {
        setContentKey('task5.4b');
      }
    }
  }

  return (
    <div className='training-redemptions-container'>
      {
        showManualScan && <div className='content'>
          <ManualScanModal scan={onScan} close={() => setShowManualScan(false)}/>
        </div>
      }
      {scanningDisabled && 
        <div className="attendance-scan-bar-unfocus">
          <span>Scanning not enabled for this step</span>
        </div>
      }
      {
        !scanningDisabled && <ScannerBar focus={focus} guestScanning={guestScanning} setShowManualScan={setShowManualScan} />
      }
      {
        !scanningDisabled && 
        <Scanner
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onScan={onScan}
          pattern={'^[B,b]-[A-Za-z0-9]{12}$'}
          timeout={500}
        />
      }
      {
        sandbox &&
        <div className='button' onClick={() => setScans([])}>
          Clear Scans
        </div>
      }
      <FilterBar
        scanCounts={scanCounts}
        filterList={filterList}
        toggleFilters={toggleFilters}
      />
      <div className='scan-filter-table records'>
        <table style={{ width: '100%', tableLayout: 'fixed' }}>
          <tbody style={{ columnCount: 5 }}>
            {showScans.map((scan, index) =>
              <ScanRow
                key={index}
                rowIdentifier={index}
                id={scan.id}
                scan={scan}
                optionsOpenID={optionsOpenID}
                toggleOptions={toggleOptions}
                showSpinner={showSpinner}
                dismissScan={dismissScan}
                issueTicket={issueTicket}
                hostScanning={hostScanning}
                guestScanning={guestScanning}
                toggleGuestScanning={toggleGuestScanning}
                redeemForEvent={redeemForEvent}
              />
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default DemoTicketRedemptions;