import React from 'react'
import { ActionCableProvider } from 'use-action-cable';
import ProgressTracker from '../ProgressTracker';
import autoload from '../../helpers/autoload'

const HotelReservationProgress = ({actionCableOn}) => {
  return (
    <ActionCableProvider url="/cable">
      <ProgressTracker 
        actionCableOn={actionCableOn}
        channelName="HotelReservationProgressChannel" 
        completionMessage="Hotel reservation import completed!" 
      />
    </ActionCableProvider>
  );
};


export default autoload(HotelReservationProgress, 'hotel-reservation-progress')